import { ICargoModel } from 'src/app/Models/cadastro/cargo.model'

export class CargoMock {
  cargoMock (): ICargoModel[] {
    return [
      { id: 1, descricao: 'PROF.ED.INF', ativo: true },
      { id: 2, descricao: 'ENS.FUND.I', ativo: true },
      { id: 3, descricao: 'SECRETÁRIO DE ESCOLA', ativo: true },
      { id: 4, descricao: 'AGENTE ESCOLAR', ativo: true },
      { id: 5, descricao: 'DIRETOR DE ESCOLA', ativo: true },
      { id: 6, descricao: 'AUXILIAR TÉCNICO DE EDUCAÇÃO', ativo: true },
      { id: 7, descricao: 'COORDENADOR PEDAGÓGICO', ativo: true },
      { id: 8, descricao: 'MED.', ativo: true },
      { id: 9, descricao: 'PROF.ENS.FUND.II', ativo: true },
      { id: 10, descricao: 'PROF.ED.INF', ativo: true }
    ] as ICargoModel[]
  }
}
