/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthenticationService } from '../services/authentication/authentication.service'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor (
    private authenticationService: AuthenticationService,
    private route: Router
  ) { }

  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const validActive = this.authenticationService.validAuthenticUser(route)
    if (validActive.loginValid && validActive.profileValid) {
      return true
    } else if (validActive.loginValid && !validActive.profileValid) {
      return false
    }
    this.route.navigate(['/login'])
    return false
  }
}
