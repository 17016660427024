import { map, Observable } from 'rxjs'
import { IUsuarioDetalheModel } from './../../../Models/configuracoes/usuario.detalhes.model';
import { Component, Injector, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { MatOptionSelectionChange } from '@angular/material/core'

import { IPerfilModel } from 'src/app/Models/configuracoes/perfil.model'
import { ConfiguracoesService } from 'src/app/services/configuracoes/configuracoes.service'
import { PromptMessageService } from 'src/app/services/modal/prompt-message.service'
import * as moment from 'moment'

@Component({
  selector: 'app-usuario-detalhe',
  templateUrl: './usuario-detalhe.component.html',
  styleUrls: ['./usuario-detalhe.component.css']
})
export class UsuarioDetalheComponent implements OnInit {
  idUsuario: string
  perfilUsuarioModel: IPerfilModel[] = []
  usuarioModel: IUsuarioDetalheModel
  servicosProfile: string[] = []
  statusLogin: string
  statusUsuario: string
  title: string
  protected activatedRoute: ActivatedRoute
  protected configuracoesService: ConfiguracoesService
  protected promptMessage: PromptMessageService
  public frmDetail: FormGroup
  readOnly = true
  perfilUsuario$: Observable<IPerfilModel[]>
  public perfilUsuario: string

  constructor (private injector: Injector) {
    this.activatedRoute = this.injector.get(ActivatedRoute)
    this.configuracoesService = this.injector.get(ConfiguracoesService)
    this.promptMessage = this.injector.get(PromptMessageService)
    this.frmDetail = this.injector.get(FormBuilder).group({
      id: [null],
      name: [null, Validators.required],
      numberDoc: [null],
      NumberCellPhone: [null],
      statusUsuario: [null],
      login: [null],
      passwordWrong: [null],
      passwordReset: [null],
      passwordExpire: [null],
      statusLogin: [null]
    })
  }

  ngOnInit () {
    this.idUsuario = this.activatedRoute.snapshot.paramMap.get('id')

    if (this.idUsuario !== '0') {
      this.title = 'Alteração no cadastro do usuário'
      this.configuracoesService.selectDetailsUser(this.idUsuario).subscribe((res: IUsuarioDetalheModel) => {
        this.usuarioModel = res
        this.setFrmDetails()
        this.perfilUsuario$ = this.configuracoesService.listaPerfil().pipe(map((result:any[]) => {
          const idProfile = this.perfilUsuarioModel[0].id
          this.perfilUsuario = this.perfilUsuarioModel[0].descricao
          this.getServicesProfile(idProfile)
          return result
        }))
      })
    } else {
      this.title = 'Cadastrar um novo usuário'
      this.perfilUsuario$ = this.configuracoesService.listaPerfil()
    }
  }

  atualiza () {
    if (this.idUsuario !== '0') {
      if (!this.validChange()) {
        this.promptMessage.openDialog('Alteraçao', 'Não houve alteração de dados')
      } else {
        this.setUsuarioModel()
        this.configuracoesService.updatePerfilUsuario({} as any).subscribe((result) => {
          if (!result.success) {
            this.promptMessage.openDialog('Alteraçao', 'Não foi possível atualizar os dados do usuário.')
          } else {
            this.promptMessage.openDialog('Alteraçao', 'Dados atualizados com sucesso.')
          }
        })
      }
    } else {
      this.novo()
    }
  }

  novo () {
    if (!this.validInsert()) {
      this.promptMessage.openDialog('Alteraçao', 'Dados inválidos verifique os campos obrigatórios.')
    } else {
      this.setUsuarioModel()
      this.idUsuario = '0'
      this.configuracoesService.insertPerfilUsuario({} as any).subscribe((result) => {
        if (result) {
          this.promptMessage.openDialog('Inclusão', 'Usuário cadastrado com sucesso.')
        } else {
          this.promptMessage.openDialog('Inclusão', 'Não foi possível realizar o cadastro do usuário.')
        }
      })
    }
  }

  setUsuarioModel () {
    this.usuarioModel.id = this.frmDetail.get('id').value
    this.usuarioModel.name = this.frmDetail.get('name').value
    this.usuarioModel.numberDoc = this.frmDetail.get('numberDoc').value
    this.usuarioModel.NumberCellPhone = this.frmDetail.get('NumberCellPhone').value
    this.usuarioModel.statusUsuario = this.frmDetail.get('statusUsuario').value
    this.usuarioModel.login = this.frmDetail.get('login').value
    this.usuarioModel.passwordWrong = this.frmDetail.get('passwordWrong').value
    this.usuarioModel.passwordReset = this.frmDetail.get('passwordReset').value
    this.usuarioModel.passwordExpire = this.frmDetail.get('passwordExpire').value
    this.usuarioModel.statusLogin = this.frmDetail.get('statusLogin').value
  }

  setFrmDetails () {
    this.frmDetail.get('id').setValue(this.usuarioModel.id)
    this.frmDetail.get('name').setValue(this.usuarioModel.name)
    this.frmDetail.get('numberDoc').setValue(this.usuarioModel.numberDoc)
    this.frmDetail.get('NumberCellPhone').setValue(this.usuarioModel.NumberCellPhone)
    this.frmDetail.get('statusUsuario').setValue(this.usuarioModel.statusUsuario)
    this.frmDetail.get('login').setValue(this.usuarioModel.login)
    this.frmDetail.get('passwordWrong').setValue(this.usuarioModel.passwordWrong)
    this.frmDetail.get('passwordReset').setValue(this.usuarioModel.passwordReset)
    this.frmDetail.get('passwordExpire').setValue(moment(this.usuarioModel.passwordExpire).format('YYYY-MM-DD'))
    this.frmDetail.get('statusLogin').setValue(this.usuarioModel.statusLogin)
    this.statusLogin = this.usuarioModel.statusLogin ? 'Ativado' : 'Desativado'
    this.statusUsuario = this.usuarioModel.statusUsuario ? 'Ativado' : 'Desativado'
    this.perfilUsuarioModel = this.usuarioModel.profiles
  }

  validChange () {
    return true
  }

  validInsert () {
    return this.frmDetail.get('descricao').value !== '' &&
           this.frmDetail.get('descricao').value !== null
  }

  onChange (event: any) {
    this.statusLogin = this.frmDetail.get('status').value ? 'Ativado' : 'Desativado'
    this.statusUsuario = this.frmDetail.get('status').value ? 'Ativado' : 'Desativado'
  }

  perfilSelecionado (event: MatOptionSelectionChange, perfil: IPerfilModel) {
    if (event.source.selected) {
      this.getServicesProfile(perfil.id)
    }
  }

  getServicesProfile (idProfile: number) {
    this.configuracoesService.selectServicesProfile(idProfile).subscribe((res: any) => {
      if (res && res.length > 0) {
        res.forEach(element => {
          if (!this.servicosProfile.find((p:string) => p === element.service)) {
            this.servicosProfile.push(element.service)
          }
        })
      } else {
        this.servicosProfile = []
      }
    })
  }
}
