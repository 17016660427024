import { IResponseModel } from 'src/app/Models/IResponseModel'
import { Component, Inject, Input } from '@angular/core'

import { AuthenticationService } from 'src/app/services/authentication/authentication.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { PromptMessageService } from 'src/app/services/modal/prompt-message.service'
import { IResetPasswordDomain } from 'src/app/Domains/authentication/reset-password-domain'

@Component({
  selector: 'app-modal-reset-message',
  templateUrl: './modal-reset-message.component.html',
  styleUrls: ['./modal-reset-message.component.css']
})
export class ModalResetMessageComponent {
  constructor (
    public dialogRef: MatDialogRef<ModalResetMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: PromptMessageService,
    private authentication: AuthenticationService,
    ) { }

  resetPasswordDomain: IResetPasswordDomain;
  senhaAtual: string
  senhaNova: string
  senhaNovaConfirmacao: string

  onCloseClick (): void {
    this.dialogRef.close()
  }

  onClick (): void {
    if (!this.validPassword()) {
      this.messageService.openDialog('Resetar senha', 'Dados informados não são validos.')
      return
    }
    this.resetPasswordDomain = {
      idLogin: this.data.idUser,
      senhaAtual: this.senhaAtual,
      novaSenha: this.senhaNova
    }
    this.authentication.resetPassword(this.resetPasswordDomain).subscribe((res: IResponseModel) => {
      if (!res.success) {
        this.messageService.openDialog('Autenticação', 'Senha alterada com sucesso.')
      } else {
        this.messageService.openDialog('Autenticação', 'Não foi possível alterar a senha.')
      }
    })
    this.onCloseClick()
  }

  validPassword (): boolean {
    if ((!this.senhaAtual || !this.senhaNova || !this.senhaNovaConfirmacao) && this.senhaNova !== this.senhaNovaConfirmacao) {
      return false
    }
    return true
  }
}
