import { Component, Injector, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSidenav } from '@angular/material/sidenav'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { ITurnoHorarioModel } from 'src/app/Models/cadastro/turno-horario.model'

import { TurnoHorarioService } from 'src/app/services/cadastros/turno-horario.service'

@Component({
  selector: 'app-turno-horario',
  templateUrl: './turno-horario.component.html',
  styleUrls: ['./turno-horario.component.css']
})
export class TurnoHorarioComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  itensPagina: number[] = [5, 10, 15];
  initialSelection = [];
  allowMultiSelect = true;
  length: number;
  pageSize: number;
  pageIndex: number;
  showFirstLastButtons = true;
  showItens = true;
  search: string;

  displayedColumns: string[] = ['id', 'horario', 'turno', 'sigla', 'ativo', 'acoes']

  protected router: Router
  protected activatedRouter: ActivatedRoute
  protected turnoHorarioService: TurnoHorarioService
  turnoHorarioModel: ITurnoHorarioModel[] = [];

  constructor (private injector: Injector) {
    this.router = this.injector.get(Router)
    this.activatedRouter = this.injector.get(ActivatedRoute)
    this.turnoHorarioService = this.injector.get(TurnoHorarioService)
  }

  ngOnInit () {
    this.turnoHorarioService.lista().subscribe((result) => {
      this.dataSource = new MatTableDataSource(result)
      this.dataSource.paginator = this.paginator
      this.length = result.length + 1
      this.pageIndex = 0
    })
  }

  incluirTurnoHorario () {
    this.router.navigate(['detalhe', 0], { relativeTo: this.activatedRouter })
  }

  alterarTurnoHorario (id: number) {
    this.router.navigate(['detalhe', id], { relativeTo: this.activatedRouter })
  }

  updateStatus (element) {
    element.ativo = !element.ativo
    const indexArr = this.turnoHorarioModel.findIndex(x => x.id === element.id)
    if (indexArr > -1) {
      this.turnoHorarioModel[indexArr].ativo = element.ativo
    } else {
      this.turnoHorarioModel.push({ id: element.id, ativo: element.ativo } as ITurnoHorarioModel)
    }
  }

  describeStatus (status: boolean): string {
    return status ? 'Ativo' : 'Desativado'
  }

  handlePageEvent (event: PageEvent) {
    this.length = event.length + 1
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
  }

  selectedMenu (url: string) {
    this.router.navigate([`/${url}`])
  }

  applyFilter (event: Event) {
    const value = (event.target as HTMLInputElement).value
    this.dataSource.filter = value.trim().toLowerCase()
  }

  onBack (): void {
    this.router.navigate(['home'])
  }
}
