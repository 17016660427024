import { Injectable, Injector } from '@angular/core'
import { Subject, takeUntil, tap } from 'rxjs'
import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpEvent, HttpDownloadProgressEvent } from '@angular/common/http'

import { IHttpParam } from './http-param'
import { HTTPURLS } from './http-urls.enum'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { UploadDownloadFileService } from './upload-download-file.service'
import { IHttpHeaders } from './http-header.'
import { LoadingService } from 'src/app/services/loading/loading.service'

@Injectable({
  providedIn: 'root'
})

export class HttpCustomClient {
  protected headers: HttpHeaders;
  protected params: HttpParams;
  protected http: HttpClient;
  protected uploadDownloadFileService: UploadDownloadFileService;
  private subscriptions$ = new Subject();
  private loadService: LoadingService;

  constructor (private inject: Injector) {
    this.headers = new HttpHeaders()
    this.http = this.inject.get(HttpClient)
    this.uploadDownloadFileService = this.inject.get(UploadDownloadFileService)
    this.loadService = this.inject.get(LoadingService)
  }

  Get (url:HTTPURLS, _params?: IHttpParam[]) {
    this.setParams(_params)
    return this.http.get(url, { params: this.params })
  }

  Post (url:HTTPURLS, domain: any, _params?: IHttpParam[]) {
    this.setParams(_params)
    return this.http.post<IResponseModel>(url, domain)
  }

  Put (url:HTTPURLS, domain: any, _params?: IHttpParam[]) {
    this.setParams(_params)
    return this.http.put<IResponseModel>(url, domain)
  }

  Delete (url:HTTPURLS, idDelete: number) {

  }

  getFile (url: HTTPURLS, objectData: any, filename: string = '', fileExtension: string = '', _params?: IHttpParam[]) {
    const name = `${filename}.${fileExtension.replace(/[^A-Za-z]/g, '')}`
    const header = this.setHeadersDownload()
    return this.http.post(url, objectData, {
      headers: header,
      responseType: 'blob',
      observe: 'events',
      reportProgress: true
    }
    )
      .pipe(
        takeUntil(this.subscriptions$),
        tap((res: HttpEvent<Object>) => {
          if (res.type === HttpEventType.DownloadProgress) {
            this.loadService.showDeterminate(this.percentLoadedFile(res as HttpDownloadProgressEvent))
          } else if (res.type === HttpEventType.Response) {
            this.loadService.hide()
            this.uploadDownloadFileService.Download(res.body, name)
          } else {
            this.loadService.showDeterminate(1)
          }
        })
      )
  }

  private percentLoadedFile (event: HttpDownloadProgressEvent): number {
    return Math.round((event.loaded * 100) / event.total)
  }

  setHeadersDownload (): HttpHeaders {
    return new HttpHeaders({ 'Content-Type': 'application/json' })
  }

  private setParams (_params?: IHttpParam[]) {
    if (!_params || _params.length < 1) {
      return []
    }
    this.params = new HttpParams()
    _params.forEach(element => {
      this.params = this.params.append(element.name, element.value)
    })
  }

  buildGetParams (getValue: object): IHttpParam[] {
    return Object.keys(getValue).map((key: string) => ({ name: key, value: getValue[key] } as IHttpParam
    ))
  }

  private setHttpOptions (
    params?: IHttpParam[],
    headers?: IHttpHeaders[]
  ): object {
    const httpOptions = {
      params: new HttpParams(),
      headers: new HttpHeaders()
    }
    if (params?.length) {
      params.forEach((param: IHttpParam) => {
        httpOptions.params = httpOptions.params.append(param.name, param.value)
      })
    }
    if (headers?.length) {
      headers.forEach((header: IHttpHeaders) => {
        httpOptions.headers = httpOptions.headers.append(header.name, header.value)
      })
    }
    return httpOptions
  }

  ngOnDestroy () {
    this.subscriptions$.unsubscribe()
  }
}
