<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="container">
<div class="cad-geral">
  <app-breadcrumb></app-breadcrumb>
  <h2>Configurações do sistema</h2>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div layoutFlex="33%" fxFlexAlign="stretch" *ngIf="false">
      <mat-card (click)="onSelect('feriados')">
        <mat-icon>calendar_today</mat-icon>
        <br>
        <mat-card-title>Feriados</mat-card-title>
        <mat-card-subtitle>Cadastro de feriados</mat-card-subtitle>
      </mat-card>
    </div>
    <div fxLayout="row" fxLayoutAlign="left left">
      <div layoutFlex="33%" fxFlexAlign="stretch">
        <mat-card (click)="onSelect('perfil')">
          <mat-icon>badge</mat-icon>
          <br>
          <mat-card-title>Perfil</mat-card-title>
          <mat-card-subtitle>Cadastro de perfil de usuário</mat-card-subtitle>
        </mat-card>
      </div>
      <div layoutFlex="33%" fxFlexAlign="stretch">
        <mat-card (click)="onSelect('usuario')">
          <mat-icon>manage</mat-icon>
          <br>
          <mat-card-title>Usuários</mat-card-title>
          <mat-card-subtitle>Cadastro de usuário do sistema</mat-card-subtitle>
        </mat-card>
      </div>
      <div layoutFlex="33%" fxFlexAlign="stretch">
        <mat-card (click)="onSelect('servico')">
          <mat-icon>task</mat-icon>
          <br>
          <mat-card-title>Serviços</mat-card-title>
          <mat-card-subtitle>Cadastro dos serviços do sistema</mat-card-subtitle>
        </mat-card>
      </div>
    </div>
  </div>
</div>
