import { Component, Injector, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { DatePipe } from '@angular/common'
import { ClasseTurmaService } from 'src/app/services/cadastros/classe-turma.service'
import { IClasseTurmaModel } from 'src/app/Models/cadastro/classe-turma.model'

@Component({
  selector: 'app-classe-turma-detalhes',
  templateUrl: './classe-turma-detalhes.component.html',
  styleUrls: ['./classe-turma-detalhes.component.css']
})
export class ClasseTurmaDetalhesComponent implements OnInit {
  idClasse: string
  classeTurmaModel: IClasseTurmaModel
  status: string
  title: string
  protected activatedRoute: ActivatedRoute
  protected ClasseTurmaService: ClasseTurmaService
  public frmDetail: FormGroup
  readOnly = true
  datePipe = new DatePipe('pt-BR');

  constructor (private injector: Injector) {
    this.activatedRoute = this.injector.get(ActivatedRoute)
    this.ClasseTurmaService = this.injector.get(ClasseTurmaService)
    this.frmDetail = this.injector.get(FormBuilder).group({
      id: [null],
      classe: [null, Validators.required],
      turma: [null, Validators.required],
      ativo: [null, Validators.required],
      dataInsercao: [null]
    })
  }

  ngOnInit () {
    this.idClasse = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.idClasse !== '0') {
      this.title = 'Alteração da classe e turma'
      this.ClasseTurmaService.detalheClasseTurma(this.idClasse).subscribe((result) => {
        this.classeTurmaModel = result
        this.setFrmDetails()
      })
    } else {
      this.title = 'Inclusão de classe e turma'
      this.classeTurmaModel = {
        id: 0,
        classe: '',
        turma: '',
        dataInsercao: new Date(Date.now()),
        ativo: true
      }
      this.setFrmDetails()
    }
  }

  atualiza () {
    if (this.classeTurmaModel.id !== 0) {
      if (!this.validChange()) {
        alert('Não houve alteração de dados')
      } else {
        this.setClasseTurmaModel()
        this.classeTurmaModel.dataInsercao = null
        this.ClasseTurmaService.atualizaClasseTurma(this.classeTurmaModel).subscribe((result) => {
          if (result === '') {
            alert('Não foi possível atualizar a classe-turma.')
          } else {
            alert(result)
          }
        }
        )
      }
    } else {
      this.novo()
    }
  }

  novo () {
    if (!this.validInsert()) {
      alert('Dados inválidos verifique os campos obrigatórios')
    } else {
      this.setClasseTurmaModel()
      this.classeTurmaModel.id = 0
      this.ClasseTurmaService.novaClasseTurma(this.classeTurmaModel).subscribe((result) => {
        if (result) {
          alert('Cadastro realizado com sucesso')
        } else {
          alert('Não foi possível realizar o cadastro.')
        }
      })
    }
  }

  setClasseTurmaModel () {
    this.classeTurmaModel.id = this.frmDetail.get('id').value
    this.classeTurmaModel.classe = this.frmDetail.get('classe').value
    this.classeTurmaModel.turma = this.frmDetail.get('turma').value
    this.classeTurmaModel.ativo = this.frmDetail.get('ativo').value
    this.classeTurmaModel.dataInsercao = this.frmDetail.get('dataInsercao').value
  }

  setFrmDetails () {
    this.frmDetail.get('id').setValue(this.classeTurmaModel.id)
    this.frmDetail.get('classe').setValue(this.classeTurmaModel.classe)
    this.frmDetail.get('turma').setValue(this.classeTurmaModel.turma)
    this.frmDetail.get('ativo').setValue(this.classeTurmaModel.ativo)
    this.frmDetail.get('dataInsercao').setValue(this.datePipe.transform(this.classeTurmaModel.dataInsercao, 'yyyy-MM-dd'))
    this.status = this.classeTurmaModel.ativo ? 'Ativado' : 'Desativado'
  }

  validChange () {
    return this.frmDetail.get('classe').value !== this.classeTurmaModel.classe ||
           this.frmDetail.get('turma').value !== this.classeTurmaModel.turma ||
           this.frmDetail.get('ativo').value !== this.classeTurmaModel.ativo
  }

  validInsert () {
    return this.frmDetail.errors.length() === 0
  }

  changed () {
    this.status = this.frmDetail.get('ativo').value ? 'Ativado' : 'Desativado'
  }
}
