import { Component, Injector, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { CargoService } from '../../../services/cadastros/cargo.service'
import { ICargoModel } from '../../../Models/cadastro/cargo.model'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-cargo-detalhes',
  templateUrl: './cargo-detalhes.component.html',
  styleUrls: ['./cargo-detalhes.component.css']
})
export class CargoDetalhesComponent implements OnInit {
  idCargo: string
  cargoModel: ICargoModel
  status: string
  title: string
  protected activatedRoute: ActivatedRoute
  protected cargoService: CargoService
  public frmDetail: FormGroup
  readOnly = true
  datePipe = new DatePipe('pt-BR');

  constructor (private injector: Injector) {
    this.activatedRoute = this.injector.get(ActivatedRoute)
    this.cargoService = this.injector.get(CargoService)
    this.frmDetail = this.injector.get(FormBuilder).group({
      idCargo: [null],
      descricao: [null, Validators.required],
      ativo: [null, Validators.required],
      dataInsercao: [null]
    })
  }

  ngOnInit () {
    this.idCargo = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.idCargo !== '0') {
      this.title = 'Alteração do cadastro de cargo'
      this.cargoService.selectCargo(this.idCargo).subscribe((result) => {
        this.cargoModel = result
        this.setFrmDetails()
      })
    } else {
      this.title = 'Inclusão de cargo'
      this.cargoModel = {
        id: 0,
        descricao: '',
        dataInsercao: new Date(Date.now()),
        ativo: true
      }
      this.setFrmDetails()
    }
  }

  atualiza () {
    if (this.cargoModel.id !== 0) {
      if (!this.validChange()) {
        alert('Não houve alteração de dados')
      } else {
        this.setCargoModel()
        this.cargoModel.dataInsercao = null
        this.cargoService.atualizaCargo(this.cargoModel).subscribe((result) => {
          if (result === '') {
            alert('Não foi possível atualizar o cargo.')
          } else {
            alert(result)
          }
        }
        )
      }
    } else {
      this.novo()
    }
  }

  novo () {
    if (!this.validInsert()) {
      alert('Dados inválidos verifique os campos obrigatórios')
    } else {
      this.setCargoModel()
      this.cargoModel.id = 0
      this.cargoService.novoCargo(this.cargoModel).subscribe((result) => {
        if (result) {
          alert('Cadastro realizado com sucesso')
        } else {
          alert('Não foi possível realizar o cadastro.')
        }
      })
    }
  }

  setCargoModel () {
    this.cargoModel.id = this.frmDetail.get('idCargo').value
    this.cargoModel.descricao = this.frmDetail.get('descricao').value
    this.cargoModel.ativo = this.frmDetail.get('ativo').value
    this.cargoModel.dataInsercao = this.frmDetail.get('dataInsercao').value
  }

  setFrmDetails () {
    this.frmDetail.get('idCargo').setValue(this.cargoModel.id)
    this.frmDetail.get('descricao').setValue(this.cargoModel.descricao)
    this.frmDetail.get('ativo').setValue(this.cargoModel.ativo)
    this.frmDetail.get('dataInsercao').setValue(this.datePipe.transform(this.cargoModel.dataInsercao, 'yyyy-MM-dd'))
    this.status = this.cargoModel.ativo ? 'Ativado' : 'Desativado'
  }

  validChange () {
    return this.frmDetail.get('descricao').value !== this.cargoModel.descricao || this.frmDetail.get('ativo').value !== this.cargoModel.ativo
  }

  validInsert () {
    return this.frmDetail.get('descricao').value !== '' &&
           this.frmDetail.get('descricao').value !== null &&
           this.frmDetail.get('dataInsercao').errors === null
  }

  changed () {
    this.status = this.frmDetail.get('ativo').value ? 'Ativado' : 'Desativado'
  }
}
