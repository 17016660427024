import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatIconModule } from '@angular/material/icon'
import { MatDividerModule } from '@angular/material/divider'
import { MatMenuModule } from '@angular/material/menu'
import { MatCardModule } from '@angular/material/card'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatOptionModule } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'

import { CadastrosRoutingModule } from './cadastros.modules.routing'
import { CadastrosComponent } from './cadastros.component'
import { DocenteComponent } from './docente/docente.component'
import { DocenteDetalheComponent } from './docente/detalhe/docente-detalhe.component'
import { SharedModule } from '../shared/shared.module'
import { CargosComponent } from './cargos/cargos.component'
import { CargoDetalhesComponent } from './cargos/detalhes/cargo-detalhes.component'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { JornadaComponent } from './jornada/jornada.component'
import { JornadaDetalhesComponent } from './jornada/detalhes/jornada-detalhes.component'
import { ClasseTurmaComponent } from './classe-turma/classe-turma.component'
import { ClasseTurmaDetalhesComponent } from './classe-turma/detalhes/classe-turma-detalhes.component'
import { PadraoComponent } from './padrao/padrao.component'
import { PadraoDetalhesComponent } from './padrao/detalhes/padrao-detalhes.component'
import { CadastrosChildAuthGuard } from '../guards/cadastros-child-auth-guard'
import { ManutencaoComponent } from './docente/manutencao/manutencao.component'
import { TurnoHorarioComponent } from './turno-horario/turno-horario.component'
import { TurnoHorarioDetalhesComponent } from './turno-horario/detalhes/turno-horario-detalhes.component'

@NgModule({
  declarations: [
    CadastrosComponent,
    DocenteComponent,
    DocenteDetalheComponent,
    CargosComponent,
    CargoDetalhesComponent,
    JornadaComponent,
    JornadaDetalhesComponent,
    ClasseTurmaComponent,
    ClasseTurmaDetalhesComponent,
    PadraoComponent,
    PadraoDetalhesComponent,
    ManutencaoComponent,
    TurnoHorarioComponent,
    TurnoHorarioDetalhesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    MatPaginatorModule,
    MatOptionModule,
    MatSelectModule,
    FlexLayoutModule,
    MatCardModule,
    MatSlideToggleModule,
    RouterModule,
    CadastrosRoutingModule
  ],
  providers: [
    CadastrosChildAuthGuard
  ]
})
export class CadastrosModule { }
