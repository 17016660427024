import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatIconModule } from '@angular/material/icon'
import { MatDividerModule } from '@angular/material/divider'
import { MatMenuModule } from '@angular/material/menu'
import { MatCardModule } from '@angular/material/card'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatOptionModule } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import {MatListModule} from '@angular/material/list'
import { SharedModule } from '../shared/shared.module'

import { ConfiguracoesRoutingModule } from './configuracoes.modules.routing'
import { ConfiguracoesComponent } from './configuracoes.component'
import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component'
import { PerfilUsuarioDetalheComponent } from './perfil-usuario/perfil-usuario-detalhe/perfil-usuario-detalhe.component'
import { UsuarioComponent } from './usuario/usuario.component'
import { UsuarioDetalheComponent } from './usuario/usuario-detalhe/usuario-detalhe.component'
import { ServicosComponent } from './servicos/servicos.component'
import { ServicoDetalheComponent } from './servicos/servico-detalhe/servico-detalhe.component'

@NgModule({
  declarations: [
    ConfiguracoesComponent,
    PerfilUsuarioComponent,
    PerfilUsuarioDetalheComponent,
    UsuarioComponent,
    UsuarioDetalheComponent,
    ServicosComponent,
    ServicoDetalheComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    MatPaginatorModule,
    MatOptionModule,
    MatSelectModule,
    FlexLayoutModule,
    MatCardModule,
    MatSlideToggleModule,
    MatListModule,
    ConfiguracoesRoutingModule
  ]
})
export class ConfiguracoesModule { }
