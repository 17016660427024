<mat-sidenav-container fxFlexFill class="example-container">
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content fxFlexFill>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div>
        <h3>Olá {{ nameUser }}</h3>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Perfil</mat-label>
          <mat-select [(value)]="profiles[0]" (selectionChange)="validPerfilServico($event.value)">
            <mat-select-trigger>
              {{profiles?.[0] || ''}}
            </mat-select-trigger>
            <mat-option *ngFor="let profile of profiles" [value]="profile">{{profile}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <h2>Serviços disponíveis</h2>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-card>
        <mat-card-title>Hora atividade</mat-card-title>
        <mat-card-subtitle
          >Registro de hora atividade do professor</mat-card-subtitle
        >
        <mat-card-actions>
          <button
            mat-button
            color="primary"
            (click)="selectedService('hora-atividade')"
          >
            Emissão
          </button>
        </mat-card-actions>
      </mat-card>
      <mat-card>
        <mat-card-title>Cadastros</mat-card-title>
        <mat-card-subtitle
          >Cadastros de jornada, turmas, especialidades...</mat-card-subtitle
        >
        <mat-card-actions>
          <button
            mat-button
            color="primary"
            (click)="selectedService('cadastros')"
          >
            Acessar
          </button>
        </mat-card-actions>
      </mat-card>
      <mat-card>
        <mat-card-title>Configurações</mat-card-title>
        <mat-card-subtitle
          >Cadastro de usuários e perfis do sistema.</mat-card-subtitle
        >
        <mat-card-actions>
          <button
            mat-button
            color="primary"
            (click)="selectedService('configuracoes')"
          >
            Acessar
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<mat-sidenav>
  <router-outlet></router-outlet>
</mat-sidenav>
