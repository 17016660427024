import { IHttpParam } from './../../shared/HttpClient/http-param';
/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { HttpCustomClient } from 'src/app/shared/HttpClient/http-custom-client'
import { HTTPURLS } from '../../shared/HttpClient/http-urls.enum'
import { ITurnoHorarioModel } from 'src/app/Models/cadastro/turno-horario.model'

@Injectable({
  providedIn: 'root'
})
export class TurnoHorarioService {
  constructor (private httpClient: HttpCustomClient) { }

  lista (): Observable<ITurnoHorarioModel[]> {
    return this.httpClient.Get(HTTPURLS.TURNOHORARIO).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as ITurnoHorarioModel[]
      } else {
        [{}] as ITurnoHorarioModel[]
      }
    }))
  }

  detalhe (id): Observable<ITurnoHorarioModel> {
    return this.httpClient.Get(HTTPURLS.DETALHETURNOHORARIO, [{ name: 'id', value: id }] as IHttpParam[]).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as ITurnoHorarioModel
      } else {
        return {} as ITurnoHorarioModel
      }
    }))
  }

  insert (turnoHorarioModel: ITurnoHorarioModel): Observable<boolean> {
    return this.httpClient.Post(HTTPURLS.INSERTTURNOHORARIO, turnoHorarioModel).pipe(map((res: IResponseModel) => {
      return res.success
    }))
  }

  update (turnoHorarioModel: ITurnoHorarioModel): Observable<boolean> {
    return this.httpClient.Post(HTTPURLS.UPDATETURNOHORARIO, turnoHorarioModel).pipe(map((res: IResponseModel) => {
      return res.success
    }))
  }
}
