import { BrowserModule } from '@angular/platform-browser'
import { LOCALE_ID, NgModule } from '@angular/core'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatDividerModule } from '@angular/material/divider'
import { MatMenuModule } from '@angular/material/menu'
import { MatCardModule } from '@angular/material/card'
import { FlexLayoutModule } from '@angular/flex-layout'
import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
import { MatSliderModule } from '@angular/material/slider'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpInterceptorCustom } from './shared/HttpClient/http-interceptor'
import { HomeModule } from './home/home.module'
import { AuthenticationService } from './services/authentication/authentication.service'
import { AuthGuard } from './guards/auth-guard'
import { HoraAtividadeModule } from './hora-atividade/hora-atividade.module'
import { CadastrosModule } from './cadastros/cadastros.module'
import { ConfiguracoesModule } from './configuracoes/configuracoes.module'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { LoadingComponent } from './loading/loading.component'
import { JwtModule, JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt'

registerLocaleData(localePt)
@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatCardModule,
    FlexLayoutModule,
    AppRoutingModule,
    HomeModule,
    CadastrosModule,
    ConfiguracoesModule,
    HoraAtividadeModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    JwtModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorCustom, multi: true },
    { provide: LOCALE_ID, useValue: 'pr-BR' },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    AuthenticationService,
    AuthGuard,
    JwtHelperService
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
