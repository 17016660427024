<div class="spinner-container" *ngIf="this.loadService.load$ | async">
  <mat-spinner></mat-spinner>
  <div>
    <h2>Aguarde...</h2>
  </div>
</div>

<div class="spinner-container" *ngIf="this.loadService.loadDeterminate$ | async as value">
  <mat-progress-spinner
    [mode]="mode"
    [value] = "value">
  </mat-progress-spinner>
  <div>
    <h2>Aguarde baixando o arquivo...</h2>
  </div>
</div>
