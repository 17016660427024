/* eslint-disable no-useless-constructor */
import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { SelectionModel } from '@angular/cdk/collections'
import { MatTableDataSource } from '@angular/material/table'

import { map } from 'rxjs'

import { IDocenteModel } from 'src/app/Models/docente/docente.model'
import { DocenteService } from 'src/app/services/cadastros/docente.service'
import { IDocenteDomain } from 'src/app/Domains/docente/docente.domain'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-hora-atividade',
  templateUrl: './hora-atividade.component.html',
  styleUrls: ['./hora-atividade.component.css']
})
export class HoraAtividadeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['selecione', 'rf', 'nome', 'especialidade']
  dataSource: MatTableDataSource<IDocenteModel>;
  itensPagina: number[] = [5, 10, 15];
  initialSelection = [];
  allowMultiSelect = true;
  selection: SelectionModel<IDocenteModel>;
  length: number;
  pageSize: number;
  pageIndex: number;
  showFirstLastButtons = true;
  rangeFormGroup: FormGroup;
  datePipe = new DatePipe('pt-BR');
  percentDownload = 0
  constructor (private docenteService: DocenteService,
                private _router: Router,
                private formBuilder: FormBuilder) { }

  ngOnInit (): void {
    const dateInit = new Date()
    dateInit.setMonth(dateInit.getMonth() - 3)
    this.selection = new SelectionModel<IDocenteModel>(this.allowMultiSelect, this.initialSelection)
    this.docenteService.lista().pipe(map((result) => {
      this.dataSource = new MatTableDataSource<IDocenteModel>(result)
      this.dataSource.paginator = this.paginator
      this.length = result.length
      this.pageIndex = 0
    })).subscribe()
    this.rangeFormGroup = this.formBuilder.group({
      start: new FormControl(dateInit, Validators.required),
      end: new FormControl(new Date(), Validators.required)
    })
  }

  isAllSelected () {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  masterToggle () {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row))
  }

  docenteSelecionado () {
    return !this.selection.isEmpty() && this.rangeFormGroup.valid
  }

  handlePageEvent (event: PageEvent) {
    this.length = event.length
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
  }

  gerarArquivoHoraAtividade () {
    this.selection.selected.forEach(selecionado => {
      this.percentDownload = 0
      const docenteDomain = {
        idDocente: selecionado.id,
        idEspecialidade: selecionado.idEspecialidade,
        nomeDocente: selecionado.nome,
        dataInicial: this.datePipe.transform(this.rangeFormGroup.get('start').value, 'dd/MM/yyyy'),
        dataFinal: this.datePipe.transform(this.rangeFormGroup.get('end').value, 'dd/MM/yyyy')
      } as IDocenteDomain
      this.docenteService.docHoraAtividade(docenteDomain)
    })
  }

  detalheDocente (docente: IDocenteModel) {
    this._router.navigate(['/cadastros/professor/detalhe', docente.idEspecialidade])
  }

  applyFilter (value: string) {
    this.dataSource.filter = value.trim().toLowerCase()
  }
}
