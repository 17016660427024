import { ServicoDetalheComponent } from './servicos/servico-detalhe/servico-detalhe.component';
import { PerfilUsuarioDetalheComponent } from './perfil-usuario/perfil-usuario-detalhe/perfil-usuario-detalhe.component';
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from '../guards/auth-guard'

import { ConfiguracoesComponent } from './configuracoes.component'
import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component'
import { UsuarioComponent } from './usuario/usuario.component';
import { UsuarioDetalheComponent } from './usuario/usuario-detalhe/usuario-detalhe.component';
import { ServicosComponent } from './servicos/servicos.component';

const routes: Routes = [
  {
    path: 'configuracoes',
    component: ConfiguracoesComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['admin'],
      breadcrumb: 'configuracoes'
    }
  },
  {
    path: 'configuracoes/perfil',
    component: PerfilUsuarioComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['admin'],
      breadcrumb: 'configuracoes/perfil'
    }
  },
  {
    path: 'configuracoes/perfil/detalhe/:id',
    component: PerfilUsuarioDetalheComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['admin'],
      breadcrumb: 'configuracoes/perfil/detalhe'
    }
  },
  {
    path: 'configuracoes/usuario',
    component: UsuarioComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['admin'],
      breadcrumb: 'configuracoes/usuario/detalhe'
    }
  },
  {
    path: 'configuracoes/usuario/detalhe/:id',
    component: UsuarioDetalheComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['admin'],
      breadcrumb: 'configuracoes/usuario/detalhe'
    }
  },
  {
    path: 'configuracoes/servico',
    component: ServicosComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['admin'],
      breadcrumb: 'configuracoes/servico'
    }
  },
  {
    path: 'configuracoes/servico/detalhe/:id',
    component: ServicoDetalheComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['admin'],
      breadcrumb: 'configuracoes/servico/detalhe'
    }
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfiguracoesRoutingModule { }
