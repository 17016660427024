import { CadastrosComponent } from './cadastros.component'
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from '../guards/auth-guard'
import { DocenteComponent } from './docente/docente.component'
import { DocenteDetalheComponent } from './docente/detalhe/docente-detalhe.component'
import { CargosComponent } from './cargos/cargos.component'
import { CargoDetalhesComponent } from './cargos/detalhes/cargo-detalhes.component'
import { JornadaComponent } from './jornada/jornada.component'
import { JornadaDetalhesComponent } from './jornada/detalhes/jornada-detalhes.component'
import { ClasseTurmaComponent } from './classe-turma/classe-turma.component'
import { ClasseTurmaDetalhesComponent } from './classe-turma/detalhes/classe-turma-detalhes.component'
import { PadraoComponent } from './padrao/padrao.component'
import { PadraoDetalhesComponent } from './padrao/detalhes/padrao-detalhes.component'
import { ManutencaoComponent } from './docente/manutencao/manutencao.component'
import { TurnoHorarioComponent } from './turno-horario/turno-horario.component'
import { TurnoHorarioDetalhesComponent } from './turno-horario/detalhes/turno-horario-detalhes.component'

const routes: Routes = [
  {
    path: 'cadastros',
    component: CadastrosComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros'
    }
  },
  {
    path: 'cadastros/cargos',
    component: CargosComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cargos'
    }
  },
  {
    path: 'cadastros/cargos/detalhe/:id',
    component: CargoDetalhesComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/cargos/detalhe'
    }
  },
  {
    path: 'cadastros/jornada',
    component: JornadaComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/jornada'
    }
  },
  {
    path: 'cadastros/jornada/detalhe/:id',
    component: JornadaDetalhesComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/jornada/detalhe'
    }
  },
  {
    path: 'cadastros/classe-turma',
    component: ClasseTurmaComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/classe-turma'
    }
  },
  {
    path: 'cadastros/classe-turma/detalhe/:id',
    component: ClasseTurmaDetalhesComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/classe-turma/detalhe'
    }
  },
  {
    path: 'cadastros/professor',
    component: DocenteComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/professor'
    }
  },
  {
    path: 'cadastros/professor/detalhe/:id',
    component: DocenteDetalheComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/professor/detalhe'
    }
  },
  {
    path: 'cadastros/professor/manutencao',
    component: ManutencaoComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/professor/manutencao'
    }
  },
  {
    path: 'cadastros/padrao',
    component: PadraoComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/padrao'
    }
  },
  {
    path: 'cadastros/padrao/detalhe/:id',
    component: PadraoDetalhesComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/padrao/detalhe'
    }
  },
  {
    path: 'cadastros/turno-horario',
    component: TurnoHorarioComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/turno-horario'
    }
  },
  {
    path: 'cadastros/turno-horario/detalhe/:id',
    component: TurnoHorarioDetalhesComponent,
    data: {
      roles: ['admin'],
      breadcrumb: 'cadastros/turno-horario/detalhe'
    }
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CadastrosRoutingModule { }
