/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

export class SessionStorageService {
  constructor () { }

  getValue (key: string): string {
    return sessionStorage.getItem(key)
  }

  setItem (key: string, value: string) {
    sessionStorage.setItem(key, value)
  }

  removeItem (key: string) {
    sessionStorage.removeItem(key)
  }

  clear () {
    sessionStorage.clear()
  }
}
