<mat-sidenav-container>
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content>
    <div class="container content mat-elevation-z4">
      <br />
      <h2>{{ title }}</h2>
      <form [formGroup]="frmDetail">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-card class="mat-card-details">
            <mat-card-title>Usuário</mat-card-title>
            <mat-card-subtitle>Dados do usuário</mat-card-subtitle>
            <mat-card-content>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label>Id</mat-label>
                  <input
                    matInput
                    formControlName="id"
                    placeholder="Id usuario"
                    readonly="readOnly"
                  />
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label>Nome</mat-label>
                  <input matInput formControlName="name" placeholder="nome" />
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label>C.P.F.</mat-label>
                  <input
                    matInput
                    formControlName="numberDoc"
                    placeholder="cpf"
                  />
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label>Telefone de contato</mat-label>
                  <input
                    matInput
                    formControlName="NumberCellPhone"
                    placeholder="contato"
                  />
                </mat-form-field>
                <div class="column">
                  <mat-slide-toggle
                    formControlName="statusUsuario"
                    (change)="onChange($event)"
                  >
                    {{ statusUsuario }}
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="mat-card-details">
            <mat-card-title>Login</mat-card-title>
            <mat-card-subtitle>Parâmetros de login</mat-card-subtitle>
            <mat-card-content>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label>login</mat-label>
                  <input
                    matInput
                    formControlName="login"
                    placeholder="login usuário"
                  />
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label>Qtde. Máx. tentativas de login</mat-label>
                  <input matInput formControlName="passwordWrong" />
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label>Senha valida até</mat-label>
                  <input
                    matInput
                    formControlName="passwordExpire"
                    type="date"
                  />
                </mat-form-field>
              </div>
              <div class="column">
                <mat-slide-toggle
                  formControlName="passwordReset"
                  (change)="onChange($event)"
                >
                  {{ "Resetar senha do usuário" }}
                </mat-slide-toggle>
              </div>
              <div class="column">
                <mat-slide-toggle
                  formControlName="statusLogin"
                  (change)="onChange($event)"
                >
                  {{ statusLogin }}
                </mat-slide-toggle>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="mat-card-details">
            <mat-card-title>Perfil</mat-card-title>
            <mat-card-subtitle>Perfil do usuário</mat-card-subtitle>
            <mat-card-content>
              <div class="column">
                <mat-label>Selecione o perfil</mat-label>
                <ng-container *ngIf="perfilUsuario$ | async as perfils">
                  <mat-select [(value)]="perfilUsuario">
                    <mat-option *ngFor="let perfil of perfils"
                      [value]="perfil.descricao"
                      (onSelectionChange)="perfilSelecionado($event, perfil)"
                      >
                      {{perfil.descricao}}
                    </mat-option>
                  </mat-select>
                </ng-container>
              </div>
              <br>
              <div class="column">
                <mat-label>Lista de serviços habilitados</mat-label>
                <mat-list>
                  <mat-list-item *ngFor="let service of servicosProfile">
                    <h5>{{service}}</h5>
                  </mat-list-item>
                </mat-list>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div>
          <button mat-raised-button color="warn">Cancelar</button>
          <button mat-raised-button color="primary" (click)="atualiza()">
            Confirmar
          </button>
        </div>
      </form>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
