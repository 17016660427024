import { Component, Injector, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { IPadraoModel } from '../../../Models/cadastro/padrao.model'
import { DatePipe } from '@angular/common'
import { PadraoService } from 'src/app/services/cadastros/padrao.service'

@Component({
  selector: 'app-padrao-detalhes',
  templateUrl: './padrao-detalhes.component.html',
  styleUrls: ['./padrao-detalhes.component.css']
})
export class PadraoDetalhesComponent implements OnInit {
  idPadrao: string
  padraoModel: IPadraoModel
  status: string
  title: string
  protected activatedRoute: ActivatedRoute
  protected padraoService: PadraoService
  public frmDetail: FormGroup
  readOnly = true
  datePipe = new DatePipe('pt-BR');

  constructor (private injector: Injector) {
    this.activatedRoute = this.injector.get(ActivatedRoute)
    this.padraoService = this.injector.get(PadraoService)
    this.frmDetail = this.injector.get(FormBuilder).group({
      idPadrao: [null],
      codigo: [null, Validators.required],
      descricao: [null],
      ativo: [null, Validators.required],
      dataInsercao: [null]
    })
  }

  ngOnInit () {
    this.idPadrao = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.idPadrao !== '0') {
      this.title = 'Alteração do cadastro de padrão'
      this.padraoService.selectPadrao(this.idPadrao).subscribe((result) => {
        this.padraoModel = result
        this.setFrmDetails()
      })
    } else {
      this.title = 'Inclusão de cadastro padrão'
      this.padraoModel = {
        id: 0,
        codigo: 'QP',
        descricao: '',
        dataInsercao: new Date(Date.now()),
        ativo: true
      }
      this.setFrmDetails()
    }
  }

  atualiza () {
    if (this.padraoModel.id !== 0) {
      if (!this.validChange()) {
        alert('Não houve alteração de dados')
      } else {
        this.setPadraoModel()
        this.padraoModel.dataInsercao = null
        this.padraoService.atualizaPadrao(this.padraoModel).subscribe((result) => {
          if (result === '') {
            alert('Não foi possível atualizar o padrão.')
          } else {
            alert(result)
          }
        }
        )
      }
    } else {
      this.novo()
    }
  }

  novo () {
    if (!this.validInsert()) {
      alert('Dados inválidos verifique os campos obrigatórios')
    } else {
      this.setPadraoModel()
      this.padraoModel.id = 0
      this.padraoService.novoPadrao(this.padraoModel).subscribe((result) => {
        if (result) {
          alert('Cadastro realizado com sucesso')
        } else {
          alert('Não foi possível realizar o cadastro.')
        }
      })
    }
  }

  setPadraoModel () {
    this.padraoModel.id = this.frmDetail.get('idPadrao').value
    this.padraoModel.codigo = this.frmDetail.get('codigo').value
    this.padraoModel.descricao = this.frmDetail.get('descricao').value
    this.padraoModel.ativo = this.frmDetail.get('ativo').value
    this.padraoModel.dataInsercao = this.frmDetail.get('dataInsercao').value
  }

  setFrmDetails () {
    this.frmDetail.get('idPadrao').setValue(this.padraoModel.id)
    this.frmDetail.get('codigo').setValue(this.padraoModel.codigo)
    this.frmDetail.get('descricao').setValue(this.padraoModel.descricao)
    this.frmDetail.get('ativo').setValue(this.padraoModel.ativo)
    this.frmDetail.get('dataInsercao').setValue(this.datePipe.transform(this.padraoModel.dataInsercao, 'yyyy-MM-dd'))
    this.status = this.padraoModel.ativo ? 'Ativado' : 'Desativado'
  }

  validChange () {
    return this.frmDetail.get('codigo').value !== this.padraoModel.codigo || this.frmDetail.get('ativo').value !== this.padraoModel.ativo
  }

  validInsert () {
    return this.frmDetail.get('codigo').value !== '' &&
           this.frmDetail.get('codigo').value !== null &&
           this.frmDetail.get('dataInsercao').errors === null
  }

  changed () {
    this.status = this.frmDetail.get('ativo').value ? 'Ativado' : 'Desativado'
  }
}
