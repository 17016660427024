/* eslint-disable no-useless-constructor */
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SessionStorageService } from '../shared/session-storage.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  profiles: string[] = []
  constructor (private router: Router,
               private sessionService: SessionStorageService
  ) {
  }

  nameUser: string

  ngOnInit (): void {
    this.nameUser = this.sessionService.getValue('name')
    const profilesResult = JSON.parse(this.sessionService.getValue('perfil'))
    profilesResult?.forEach(element => {
      this.profiles.push(element.value)
    })
  }

  selectedService (url: string) {
    this.router.navigate([`/${url}`])
  }

  validPerfilServico (perfil: any) {
    console.log(perfil)
  }
}
