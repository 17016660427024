import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SessionStorageService } from '../../session-storage.service';

@Component({
  selector: 'app-modal-doc-html',
  templateUrl: './modal-doc-html.component.html',
  styleUrls: ['./modal-doc-html.component.css']
})
export class ModalDocHtmlComponent {
  public document;
  htmlBind: string
  constructor (
    public dialogRef: MatDialogRef<ModalDocHtmlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sessionStorage: SessionStorageService
  ) {
    this.document = data.document
    this.htmlBind = data.document
  }

  onCloseClick (): void {
    this.dialogRef.close()
  }

  imprimir (): void {
    const printContent = this.htmlBind
    const WindowPrt = window.open('', 'name_', 'height=600,width=900,status=yes,toolbar=no,menubar=no,location=no,resizable=yes,scrollbars=yes')
    WindowPrt.document.write(printContent)
    WindowPrt.document.close()
    WindowPrt.focus()
    WindowPrt.print()
    WindowPrt.close()
  }
}
