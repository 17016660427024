import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { distinctUntilChanged, filter, map } from 'rxjs'

import { IBreadcrumb } from '../interfaces/breadcrumb'
import { BreadcrumbService } from '../service/breadcrumb.service'
import { INavItem } from './../model/nav-item'
import { menu } from '../model/menu'
import { UrlService } from 'src/app/services/breadcrumb/url.service'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: IBreadcrumb[] = []
  currentUrl: string = ''

  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlService,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit () {
    this.setDefaultBreadcrumb()
    this.listenForRouteChange()
  }

  private setDefaultBreadcrumb () {
    this.setCurrentUrl()
    this.breadcrumbs = []
    this.breadcrumbs = this.breadcrumbService.getBreadcrumb()
    if (this.currentUrl) {
      const navItem: INavItem = this.findRoute()
      if (navItem) {
        const breadcrumb: IBreadcrumb = { name: navItem.displayName, url: this.currentUrl }
        this.breadcrumbService.setBreadcrumbs(breadcrumb)
        this.breadcrumbs = this.breadcrumbService.getBreadcrumb()
      }
    }
  }

  private listenForRouteChange () {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild
        }
        return route
      }),
      distinctUntilChanged()
    ).subscribe(
      (route: ActivatedRoute) => {
        this.handleCurrentRoute(route)
      }
    )
  }

  private setCurrentUrl () {
    const url: string = this.router.url
    if (url) {
      this.currentUrl = this.urlService.shortenUrlIfNecessary(url.substring(1))
    }
  }

  private handleCurrentRoute (route: ActivatedRoute) {
    this.setCurrentUrl()
    this.addBreadcrumb(route)

    const navItem: INavItem = this.findRoute(menu)

    if (navItem) {
      this.handleTopLevelBreadcrumb(navItem)
    }
  }

  private addBreadcrumb (route: ActivatedRoute) {
    let breadcrumb = {} as IBreadcrumb
    route.data.subscribe((data: any) => {
      breadcrumb = { name: this.currentUrl, url: this.currentUrl, pauseDisplay: data.pauseDisplay }
    })
    if (breadcrumb) {
      route.queryParams.subscribe((queryParams: any) => {
        if (queryParams) {
          breadcrumb.queryParams = queryParams
        }
      })
      this.breadcrumbService.setBreadcrumbs(breadcrumb)
      this.breadcrumbs = this.breadcrumbService.getBreadcrumb()
    }
  }

  private handleTopLevelBreadcrumb (navItem: INavItem) {
    this.breadcrumbs = []
    const breadcrumb: IBreadcrumb = { name: navItem.displayName, url: navItem.route }
    this.breadcrumbService.setBreadcrumbs(breadcrumb)
    this.breadcrumbs = this.breadcrumbService.getBreadcrumb()
  }

  private findRoute (navItems?: INavItem[]): INavItem {
    if (!navItems) navItems = menu
    let returnedItem: INavItem = null
    if (this.currentUrl) {
      for (const item of navItems) {
        if (this.activatedRoute.snapshot.data.breadcrumb === item.routeActivated) {
          if (item.route.includes('{id}')) {
            item.route = item.route.replace('{id}', this.activatedRoute.snapshot.params.id)
          } else {
            item.route = this.currentUrl
          }
          returnedItem = item
          break
        }
      }
    }
    return returnedItem
  }

  routeTo (index: number) {
    if (index < this.breadcrumbs.length - 1) {
      this.breadcrumbs.splice(index + 1)
    }

    const breadcrumb: IBreadcrumb = this.breadcrumbs[index]
    const route = breadcrumb.url
    this.router.navigate([`/${route}`], { queryParams: breadcrumb.queryParams })
  }
}
