import { Component, Injector, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { IJornadaModel } from '../../../Models/cadastro/jornada.model'
import { DatePipe } from '@angular/common'
import { JornadaService } from 'src/app/services/cadastros/jornada.service'

@Component({
  selector: 'app-jornada-detalhes',
  templateUrl: './jornada-detalhes.component.html',
  styleUrls: ['./jornada-detalhes.component.css']
})
export class JornadaDetalhesComponent implements OnInit {
  idJornada: string
  jornadaModel: IJornadaModel
  status: string
  title: string
  protected activatedRoute: ActivatedRoute
  protected jornadaService: JornadaService
  public frmDetail: FormGroup
  readOnly = true
  datePipe = new DatePipe('pt-BR');

  constructor (private injector: Injector) {
    this.activatedRoute = this.injector.get(ActivatedRoute)
    this.jornadaService = this.injector.get(JornadaService)
    this.frmDetail = this.injector.get(FormBuilder).group({
      idJornada: [null],
      codigo: [null, Validators.required],
      descricao: [null],
      ativo: [null, Validators.required],
      dataInsercao: [null]
    })
  }

  ngOnInit () {
    this.idJornada = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.idJornada !== '0') {
      this.title = 'Alteração do cadastro de jornada'
      this.jornadaService.selectJornada(this.idJornada).subscribe((result) => {
        this.jornadaModel = result
        this.setFrmDetails()
      })
    } else {
      this.title = 'Inclusão de jornada'
      this.jornadaModel = {
        id: 0,
        codigo: '',
        descricao: '',
        dataInsercao: new Date(Date.now()),
        ativo: true
      }
      this.setFrmDetails()
    }
  }

  atualiza () {
    if (this.jornadaModel.id !== 0) {
      if (!this.validChange()) {
        alert('Não houve alteração de dados')
      } else {
        this.setJornadaModel()
        this.jornadaModel.dataInsercao = null
        this.jornadaService.atualizaCargo(this.jornadaModel).subscribe((result) => {
          if (result === '') {
            alert('Não foi possível atualizar o cargo.')
          } else {
            alert(result)
          }
        }
        )
      }
    } else {
      this.novo()
    }
  }

  novo () {
    if (!this.validInsert()) {
      alert('Dados inválidos verifique os campos obrigatórios')
    } else {
      this.setJornadaModel()
      this.jornadaModel.id = 0
      this.jornadaService.novaJornada(this.jornadaModel).subscribe((result) => {
        if (result) {
          alert('Cadastro realizado com sucesso')
        } else {
          alert('Não foi possível realizar o cadastro.')
        }
      })
    }
  }

  setJornadaModel () {
    this.jornadaModel.id = this.frmDetail.get('idJornada').value
    this.jornadaModel.codigo = this.frmDetail.get('codigo').value
    this.jornadaModel.descricao = this.frmDetail.get('descricao').value
    this.jornadaModel.ativo = this.frmDetail.get('ativo').value
    this.jornadaModel.dataInsercao = this.frmDetail.get('dataInsercao').value
  }

  setFrmDetails () {
    this.frmDetail.get('idJornada').setValue(this.jornadaModel.id)
    this.frmDetail.get('codigo').setValue(this.jornadaModel.codigo)
    this.frmDetail.get('descricao').setValue(this.jornadaModel.descricao)
    this.frmDetail.get('ativo').setValue(this.jornadaModel.ativo)
    this.frmDetail.get('dataInsercao').setValue(this.datePipe.transform(this.jornadaModel.dataInsercao, 'yyyy-MM-dd'))
    this.status = this.jornadaModel.ativo ? 'Ativado' : 'Desativado'
  }

  validChange () {
    return this.frmDetail.get('codigo').value !== this.jornadaModel.codigo || this.frmDetail.get('ativo').value !== this.jornadaModel.ativo
  }

  validInsert () {
    return this.frmDetail.get('codigo').value !== '' &&
           this.frmDetail.get('codigo').value !== null &&
           this.frmDetail.get('dataInsercao').errors === null
  }

  changed () {
    this.status = this.frmDetail.get('ativo').value ? 'Ativado' : 'Desativado'
  }
}
