/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class UploadDownloadFileService {
  constructor () {}

  Download (res: any, filename: string) {
    if (res.type === 'application/json') {
      const reader = new FileReader()
      reader.onload = (r) => {
        const errorObject = JSON.parse(JSON.stringify(r.target?.result.toString()))
        console.log(errorObject.message)
      }
      reader.readAsText(res)
    } else {
      const file = new Blob([res], {
        type: res.type
      })
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(file)
        return
      }
      const blob = window.URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = blob
      link.download = filename

      link.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      }))
    }
  }
}
