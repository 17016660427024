/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { HttpCustomClient } from 'src/app/shared/HttpClient/http-custom-client'
import { HTTPURLS } from '../../shared/HttpClient/http-urls.enum'
import { IJornadaModel } from 'src/app/Models/cadastro/jornada.model'

@Injectable({
  providedIn: 'root'
})
export class JornadaService {
  constructor (private httpClient: HttpCustomClient) { }

  selectJornada (idJornada: string): Observable<IJornadaModel> {
    return this.httpClient.Get(HTTPURLS.CONSULTAJORNADA, [{ name: 'id', value: idJornada }]).pipe(map((res: IResponseModel) => {
      return res.data as IJornadaModel
    }))
  }

  listaJornada (): Observable<IJornadaModel[]> {
    return this.httpClient.Get(HTTPURLS.JORNADA).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as IJornadaModel[]
      } else {
        [{}] as IJornadaModel[]
      }
    }))
  }

  atualizaCargo (cargo: IJornadaModel): Observable<string> {
    return this.httpClient.Put(HTTPURLS.ATUALIZAJORNADA, cargo).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.message
      } else {
        return ''
      }
    }))
  }

  novaJornada (cargo: IJornadaModel): Observable<boolean> {
    return this.httpClient.Post(HTTPURLS.NOVAJORNADA, cargo).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return true
      } else {
        return false
      }
    }))
  }
}
