import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadSubject = new BehaviorSubject<boolean>(false)
  private loadDeterminate = new BehaviorSubject<number>(0)
  load$: Observable<boolean> = this.loadSubject.asObservable()
  loadDeterminate$: Observable<number> = this.loadDeterminate.asObservable()

  hide () {
    this.loadSubject.next(false)
    this.loadDeterminate.next(null)
  }

  show () {
    this.loadSubject.next(true)
  }

  showDeterminate (value: number) {
    this.loadDeterminate.next(value)
  }
}
