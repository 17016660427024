import { ModalPeriodoComponent } from './../../shared/mat-dialog/modal-periodo/modal-periodo.component';
/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatDialogCustomComponent } from '../../shared/mat-dialog/mat-dialog-custom/mat-dialog-custom.component'

@Injectable({
  providedIn: 'root'
})
export class PromptMessageService {
  constructor (private dialog: MatDialog) { }

  openDialog (titleMessage: string, dataMessage: any, dataWidth?: string, icon: boolean = true): void {
    const dialogRef = this.dialog.open(MatDialogCustomComponent, {
      width: dataWidth || '500px',
      data: { title: titleMessage, message: dataMessage, icon },
      panelClass: 'custom-container'
    })
    dialogRef.afterClosed().subscribe()
  }

  openDialogPeriodo (titleMessage: string, dataMessage: any, dataWidth?: string, icon: boolean = true): void {
    const dialogRef = this.dialog.open(ModalPeriodoComponent, {
      width: dataWidth || '500px',
      data: { title: titleMessage, message: dataMessage, icon },
      panelClass: 'custom-container'
    })
    dialogRef.afterClosed().subscribe()
  }
}
