import { Component, Injector, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-cadastros',
  templateUrl: './cadastros.component.html',
  styleUrls: ['./cadastros.component.css']
})
export class CadastrosComponent implements OnInit {
  protected router: Router;
  protected activatedRoute: ActivatedRoute
  constructor (protected injector: Injector) {
    this.router = this.injector.get(Router)
    this.activatedRoute = this.injector.get(ActivatedRoute)
  }

  ngOnInit (): void {
  }

  onSelect (action: string) {
    this.router.navigate([`${action}`], { relativeTo: this.activatedRoute })
  }
}
