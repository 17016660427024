/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { HttpCustomClient } from 'src/app/shared/HttpClient/http-custom-client'
import { HTTPURLS } from '../../shared/HttpClient/http-urls.enum'
import { IPadraoModel } from 'src/app/Models/cadastro/padrao.model'

@Injectable({
  providedIn: 'root'
})
export class PadraoService {
  constructor (private httpClient: HttpCustomClient) { }

  selectPadrao (idPadrao: string): Observable<IPadraoModel> {
    return this.httpClient.Get(HTTPURLS.CONSULTAPADRAO, [{ name: 'id', value: idPadrao }]).pipe(map((res: IResponseModel) => {
      return res.data as IPadraoModel
    }))
  }

  listaPadrao (): Observable<IPadraoModel[]> {
    return this.httpClient.Get(HTTPURLS.PADRAO).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as IPadraoModel[]
      } else {
        [{}] as IPadraoModel[]
      }
    }))
  }

  atualizaPadrao (padrao: IPadraoModel): Observable<string> {
    return this.httpClient.Put(HTTPURLS.ATUALIZAPADRAO, padrao).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.message
      } else {
        return ''
      }
    }))
  }

  novoPadrao (cargo: IPadraoModel): Observable<boolean> {
    return this.httpClient.Post(HTTPURLS.NOVOPADRAO, cargo).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return true
      } else {
        return false
      }
    }))
  }
}
