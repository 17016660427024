/* eslint-disable no-useless-constructor */
import { OnInit, Component, Inject } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Subject } from 'rxjs'

import { IPeriodoResultModel } from './periodo-result.model'

@Component({
  selector: 'app-modal-periodo',
  templateUrl: './modal-periodo.component.html',
  styleUrls: ['./modal-periodo.component.css']
})
export class ModalPeriodoComponent implements OnInit {
  rangeFormGroup: FormGroup;
  datePipe = new DatePipe('pt-BR');
  periodoResult: Subject<IPeriodoResultModel>
  constructor (
    public dialogRef: MatDialogRef<ModalPeriodoComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private formBuilder: FormBuilder
  ) { }

  ngOnInit () {
    const dateInit = new Date()
    dateInit.setMonth(dateInit.getMonth() - 3)
    this.rangeFormGroup = this.formBuilder.group({
      start: new FormControl(dateInit, Validators.required),
      end: new FormControl(new Date(), Validators.required)
    })
    this.periodoResult = new Subject()
  }

  selectPeriod () {
    const result = {
      dataInicial: this.rangeFormGroup.get('start').value,
      dataFinal: this.rangeFormGroup.get('end').value
    } as IPeriodoResultModel
    this.periodoResult.next(result)
  }

  onCloseClick (): void {
    this.dialogRef.close()
  }
}
