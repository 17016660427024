export enum HTTPURLS {
  AUTHENTICATION = 'Authentication\\login',
  AUTHENTICATIONRESETPASSWORD = 'Authentication\\reset-password',
  FORGETPASSWORD = "Authentication\\forget-password",
  DOCENTELISTA = 'docente\\lista',
  DOCENTEPESQUISA = 'docente\\pesquisa',
  HORAATIVIDADE = 'docente\\arquivo-hora-atividade',
  DOCHORAATIVIDADE = 'docente\\doc-hora-atividade',
  TURNOHORARIO = 'turnohorario',
  DETALHETURNOHORARIO = 'turnohorario\\detalhe',
  INSERTTURNOHORARIO = 'turnohorario\\insert',
  UPDATETURNOHORARIO = 'turnohorario\\update',
  DOCENTEDETALHE = 'docente\\detalhe',
  UPDATEHADOCENTE = 'docente\\update-hora-atividade',
  ATUALIZASTATUSPROFESSOR = "docente\\atualiza-status-professor",
  DOCENTELISTAMANUTENCAO = "docente\\lista-manutencao",
  ESPECIALIDADE = 'cadastro\\especialidade',
  CARGO = 'cargo',
  CONSULTACARGO = 'cargo\\detalhe',
  ATUALIZACARGO = 'cargo',
  NOVOCARGO = 'cargo',
  CLASSETURMA = 'classeturma',
  JORNADA = 'jornada',
  UPDATEDETAILSDOCENTE = "docente\\update-details",
  CONSULTAJORNADA = 'jornada\\detalhe',
  ATUALIZAJORNADA = 'jornada',
  NOVAJORNADA = 'jornada',
  CONSULTACLASSETURMA = 'classeturma',
  DETALHECLASSETURMA = 'classeturma\\detalhe',
  ATUALIZACLASSE = 'classeturma',
  NOVACLASSE = 'classeturma',
  INSERTDOCENTE = "docente\\insert",
  PADRAO = "cadastro\\padrao",
  CATEGORIA = "cadastro\\categoria",
  CONSULTAPADRAO = "cadastro\\padrao\\detalhe",
  ATUALIZAPADRAO = "cadastro\\padrao",
  NOVOPADRAO = "cadastro\\padrao",
  CONFIGURACOESLISTAPERFIL = "profile\\list",
  CONFIGURACOEPERFILUSUARIOBYID = "profile\\list-byId",
  CONFIGURACOEPERFILUSUARIOUPDATE = "profile\\",
  CONFIGURACOEPERFILUSUARIOINSERT = "profile\\insert",
  CONFIGURACOESLISTAUSUARIO = "user\\list",
  CONFIGURACOEUSUARIOBYID = "user\\detail-user",
  CONFIGURACOEUSUARIOUPDATE = "user\\",
  CONFIGURACOEUSUARIOINSERT = "user\\insert",
  CONFIGURACOESLISTASERVICOS = "service\\list",
  CONFIGURACOESSERVICOSBYID = "service\\list-byId",
  CONFIGURACOESSERVICEINSERT = "service\\insert",
  CONFIGURACOESSERVICEUPDATE = "service\\",
  CONFIGURACOESSERVICOSPERFIL = "service\\services-profile",
}
