/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { HttpCustomClient } from 'src/app/shared/HttpClient/http-custom-client'
import { HTTPURLS } from '../../shared/HttpClient/http-urls.enum'

import { IUsuarioDetalheModel } from './../../Models/configuracoes/usuario.detalhes.model';
import { IPerfilModel } from './../../Models/configuracoes/perfil.model'
import { IUsuarioModel } from 'src/app/Models/configuracoes/usuario.model'
import { IEmailModel } from 'src/app/Models/configuracoes/email.model'
import { IServicoModel } from 'src/app/Models/configuracoes/servico.model'
import { IHttpParam } from 'src/app/shared/HttpClient/http-param'

@Injectable({
  providedIn: 'root'
})
export class ConfiguracoesService {
  constructor (private httpClient: HttpCustomClient) { }
  selectServicesProfile (idProfile: number) : Observable<IServicoModel[]> {
    return this.httpClient.Get(HTTPURLS.CONFIGURACOESSERVICOSPERFIL, [{ name: 'idProfile', value: idProfile }] as IHttpParam[]).pipe(map((res: IResponseModel) => {
      return res.data as IServicoModel[]
    }))
  }

  listaUsuario () : Observable<IUsuarioModel[]> {
    return this.httpClient.Get(HTTPURLS.CONFIGURACOESLISTAUSUARIO).pipe(map((res: IResponseModel) => {
      if (res.success) {
        const resultModel = [] as IUsuarioModel[]
        const resultData = res.data as any
        resultData.forEach(model => {
          resultModel.push({
            id: model.id,
            name: model.name,
            userName: model.user_name,
            status: model.activated
          } as IUsuarioModel)
        })
        return resultModel
      } else {
        [{}] as IUsuarioModel[]
      }
    }))
  }

  listaPerfil (): Observable<IPerfilModel[]> {
    return this.httpClient.Get(HTTPURLS.CONFIGURACOESLISTAPERFIL).pipe(map((res: IResponseModel) => {
      if (res.success) {
        const resultData: IPerfilModel[] = []
        const elements = res.data as []
        elements.forEach((element: any) => {
          resultData.push({
            id: element.id,
            descricao: element.description,
            status: element.activated
          } as IPerfilModel)
        })
        return resultData
      } else {
        [{}] as IPerfilModel[]
      }
    }))
  }

  selectPerfilUsuario (idPerfil: number) : Observable<IPerfilModel> {
    return this.httpClient.Get(
      HTTPURLS.CONFIGURACOEPERFILUSUARIOBYID,
      [{ name: 'idProfile', value: idPerfil }]).pipe(map((res: IResponseModel) => {
      if (res.success) {
        const element = res.data as any
        const resultData = {
          id: element.id,
          descricao: element.description,
          status: element.activated
        } as IPerfilModel
        return resultData
      } else {
        return {} as IPerfilModel
      }
    }))
  }

  updatePerfilUsuario (perfilModel: IPerfilModel) : Observable<IResponseModel> {
    return this.httpClient.Put(HTTPURLS.CONFIGURACOEPERFILUSUARIOUPDATE, perfilModel)
  }

  insertPerfilUsuario (perfilModel: IPerfilModel) : Observable<IResponseModel> {
    return this.httpClient.Post(HTTPURLS.CONFIGURACOEPERFILUSUARIOINSERT, perfilModel)
  }

  selectDetailsUser (idUsuario: string) : Observable<IUsuarioDetalheModel> {
    return this.httpClient.Get(HTTPURLS.CONFIGURACOEUSUARIOBYID, [{ name: 'idUsuario', value: idUsuario }])
      .pipe(map((result: IResponseModel) => {
        if (result.success) {
          const data = result.data as any
          const resultPerfilUsuario: IPerfilModel[] = []
          const resultEmailUsuario: IEmailModel[] = []

          data.profiles.forEach(element => {
            resultPerfilUsuario.push({ id: element.id, descricao: element.profile } as IPerfilModel)
          })

          data.emails.forEach(element => {
            resultEmailUsuario.push({ id: element.id, email: element.email, idUser: element.id_user, activated: element.activated } as IEmailModel)
          })

          return {
            id: data.userResult.id,
            name: data.userResult.name,
            numberDoc: data.userResult.numberDoc,
            statusUsuario: data.userResult.activated,
            NumberCellPhone: data.userResult.numberCellPhone,
            login: data.loginResult.login,
            passwordWrong: data.loginResult.passwordWrong,
            passwordReset: data.loginResult.passwordReset,
            passwordExpire: data.loginResult.passwordExpire,
            statusLogin: data.loginResult.ativo,
            profiles: resultPerfilUsuario,
            emails: resultEmailUsuario
          } as IUsuarioDetalheModel
        } else {
          return {} as IUsuarioDetalheModel
        }
      }))
  }

  listaServicos () {
    return this.httpClient.Get(HTTPURLS.CONFIGURACOESLISTASERVICOS).pipe(map((res: IResponseModel) => {
      if (res.success) {
        const resultModel = [] as IServicoModel[]
        const resultData = res.data as any
        resultData.forEach(model => {
          resultModel.push({
            id: model.id,
            descricao: model.description,
            status: model.activated
          } as IServicoModel)
        })
        return resultModel
      } else {
        [{}] as IServicoModel[]
      }
    }))
  }

  selectServico (idServico: number) : Observable<IServicoModel> {
    return this.httpClient.Get(
      HTTPURLS.CONFIGURACOESSERVICOSBYID,
      [{ name: 'idService', value: idServico }]).pipe(map((res: IResponseModel) => {
      if (res.success) {
        const element = res.data as any
        const resultData = {
          id: element.id,
          descricao: element.description,
          status: element.activated,
          perfils: element.profiles
        } as IServicoModel
        return resultData
      } else {
        return {} as IServicoModel
      }
    }))
  }

  updateServico (servicoModel: IServicoModel) : Observable<IResponseModel> {
    return this.httpClient.Put(HTTPURLS.CONFIGURACOESSERVICEUPDATE, servicoModel)
  }

  insertServico (servicoModel: IServicoModel) : Observable<IResponseModel> {
    return this.httpClient.Post(HTTPURLS.CONFIGURACOESSERVICEINSERT, servicoModel)
  }

  selectDetailsService (idServico: string) : Observable<IServicoModel> {
    return this.httpClient.Get(HTTPURLS.CONFIGURACOESSERVICOSBYID, [{ name: 'idServico', value: idServico }])
      .pipe(map((result: IResponseModel) => {
        if (result.success) {
          const data = result.data as any
          return {
            id: data.id,
            descricao: data.description,
            status: data.created
          } as IServicoModel
        } else {
          return {} as IServicoModel
        }
      }))
  }
}
