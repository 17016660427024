<mat-sidenav-container>
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content>
    <div class="content mat-elevation-z4">
      <br />
      <h2>{{ title }}</h2>
      <form [formGroup]="frmDetail">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-card class="mat-card-details">
            <mat-card-title>Serviço</mat-card-title>
            <mat-card-subtitle>Dados do serviço cadastrado</mat-card-subtitle>
            <mat-card-content>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label>Id</mat-label>
                  <input
                    matInput
                    formControlName="idServico"
                    placeholder="Id serviço"
                    readonly="readOnly"
                  />
                </mat-form-field>
              </div>
              <div class="column">
                <mat-form-field appearance="fill">
                  <mat-label>Descrição</mat-label>
                  <input
                    matInput
                    formControlName="descricao"
                    placeholder="descrição"
                  />
                </mat-form-field>
              </div>
              <div class="column">
                <mat-slide-toggle
                  formControlName="status"
                  (change)="onChange()"
                >
                  {{ status }}
                </mat-slide-toggle>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="mat-card-details-perfil">
            <mat-card-title>Perfil</mat-card-title>
            <mat-card-subtitle
              >Defina o perfil de usuário com acesso ao
              serviço</mat-card-subtitle
            >
            <mat-card-content>
              <div class="column table">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                  </ng-container>
                  <ng-container matColumnDef="descricao">
                    <th mat-header-cell *matHeaderCellDef>Descrição</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.descricao }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-slide-toggle [checked]="element.status" (change)="onChangePerfil($event, element)">
                        {{ element.status ? "Ativado" : "Desativado" }}
                      </mat-slide-toggle>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="column">
          <button mat-raised-button color="warn">Cancelar</button>
          <button mat-raised-button color="primary" (click)="atualiza()">
            Confirmar
          </button>
        </div>
      </form>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
