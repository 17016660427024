import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { IBreadcrumb } from '../interfaces/breadcrumb'
import { menu } from '../model/menu'

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private subject = new Subject<string>()
  private breadcrumbs: IBreadcrumb[] = []
  onUpdate (): Observable<string> {
    return this.subject.asObservable()
  }

  updateBreadcrum (value: string) {
    this.subject.next(value)
  }

  setBreadcrumbs (breadCrumb: IBreadcrumb): void {
    const urlArray = breadCrumb.url.split('/')
    const breadcrumb: IBreadcrumb[] = []
    if (!urlArray.find(p => p === 'home')) {
      breadcrumb.push({ name: 'home', url: 'home' } as IBreadcrumb)
    }
    breadcrumb.push({ name: urlArray[0], url: urlArray[0] } as IBreadcrumb)
    this.breadcrumbs = breadcrumb

    if (urlArray.length > 1) {
      const menuSearched = menu.find(f => f.displayName === urlArray[0])
      if (menuSearched) {
        if (menuSearched.children) {
          urlArray.forEach(url => {
            const children = menuSearched.children.find(element => element.displayName === url)
            if (children) {
              breadcrumb.push({ name: children.displayName, url: children.route })
              this.breadcrumbs = breadcrumb
            }
          })
        }
      }
    }
  }

  getBreadcrumb (): IBreadcrumb[] {
    return this.breadcrumbs
  }
}
