/* eslint-disable no-useless-constructor */
/* eslint-disable lines-between-class-members */
import { Component } from '@angular/core'
import { ProgressSpinnerMode } from '@angular/material/progress-spinner'
import { LoadingService } from '../services/loading/loading.service'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
  constructor (public loadService: LoadingService) {
  }
  mode: ProgressSpinnerMode = 'determinate'
}
