/* eslint-disable no-useless-constructor */
import { ActivatedRouteSnapshot } from '@angular/router';
import { IResetPasswordDomain } from '../../Domains/authentication/reset-password-domain';
import { EventEmitter, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { JwtHelperService } from '@auth0/angular-jwt'
import { MatDialog } from '@angular/material/dialog'

import { ILoginUserDomain } from 'src/app/Domains/authentication/login-user-domain'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { HttpCustomClient } from 'src/app/shared/HttpClient/http-custom-client'
import { HTTPURLS } from 'src/app/shared/HttpClient/http-urls.enum'
import { IHttpParam } from '../../shared/HttpClient/http-param'
import { SessionStorageService } from '../../shared/session-storage.service'
import { ModalResetMessageComponent } from 'src/app/shared/mat-dialog/modal-reset-message/modal-reset-message.component'
import { ILoginProfileModel } from './../../Models/authentication/login-profile.model'
import { AuthenticationInterfaceService } from './authentication-interface.service'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements AuthenticationInterfaceService {
  showMenuEmitter = new EventEmitter<boolean>();
  constructor (private httpClient: HttpCustomClient,
    private sessionStorage: SessionStorageService,
    private dialog: MatDialog,
    private helper: JwtHelperService
  ) {
    this.helper = new JwtHelperService()
  }

  private params: IHttpParam[] ;
  loginUser (user: ILoginUserDomain) {
    const authenticationCommand = {
      userName: user.usuario,
      password: user.password,
      forgetPassword: false
    }
    this.sessionStorage.clear()
    return this.httpClient.Post(HTTPURLS.AUTHENTICATION, authenticationCommand).pipe(map((res: IResponseModel) => {
      if (!res.success) {
        this.sessionStorage.clear()
        this.showMenuEmitter.emit(false)
      } else {
        const tokenValue = res.data as any
        const jsonValue = JSON.parse(JSON.stringify(this.helper.decodeToken(tokenValue.value)))
        const usuario = {
          login: jsonValue.name,
          name: jsonValue.nameid,
          email: jsonValue.email,
          perfil: jsonValue.profile
        }
        this.sessionStorage.setItem('login', usuario.login)
        this.sessionStorage.setItem('name', usuario.name)
        this.sessionStorage.setItem('email', JSON.parse(JSON.stringify(usuario.email)))
        this.sessionStorage.setItem('perfil', JSON.parse(JSON.stringify(usuario.perfil)))
        this.sessionStorage.setItem('token', tokenValue.value)
        this.showMenuEmitter.emit(true)
      }
      return res
    }))
  }

  validAuthenticUser (activatedRouteSnapshot: ActivatedRouteSnapshot): ILoginProfileModel {
    const token = this.sessionStorage.getValue('token')
    if (!token) {
      this.showMenuEmitter.emit(false)
      this.sessionStorage.clear()
      return { profileValid: false, loginValid: false } as ILoginProfileModel
    }
    this.showMenuEmitter.emit(true)
    const validProfile = this.validProfileUser(activatedRouteSnapshot)
    return { profileValid: validProfile, loginValid: true } as ILoginProfileModel
  }

  validProfileUser (activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
    const roles = activatedRouteSnapshot.data.roles as string[]
    const perfil = JSON.parse(this.sessionStorage.getValue('perfil'))
    const profileResult: string[] = []
    Object.keys(perfil).forEach(p => {
      if (roles.find(r => r === perfil[p].value)) {
        profileResult.push(perfil[p].value)
      }
    })
    return profileResult.length > 0
  }

  forgetPassword (emailValue: string) : Observable<boolean> {
    const valueBody = {
      email: emailValue
    }

    return this.httpClient.Post(HTTPURLS.FORGETPASSWORD, valueBody).pipe(map((res: IResponseModel) => {
      return res.success
    }))
  }

  resetPassword (domain: IResetPasswordDomain): Observable<IResponseModel> {
    return this.httpClient.Post(HTTPURLS.AUTHENTICATIONRESETPASSWORD, domain)
  }

  resetPassWordModal (idUsuario: number) {
    this.dialog.open(ModalResetMessageComponent, {
      width: '500px',
      data: { idUser: idUsuario },
      panelClass: 'custom-container'
    })
  }
}
