import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from '../guards/auth-guard'
import { HoraAtividadeComponent } from './hora-atividade.component'

const routes: Routes = [
  {
    path: 'hora-atividade',
    component: HoraAtividadeComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['admin'],
      breadcrumb: 'hora-atividade'
    }
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HoraAtividadeRoutingModule { }
