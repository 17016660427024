import { Component, Injector, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSidenav } from '@angular/material/sidenav'
import { MatTableDataSource } from '@angular/material/table'
import { Router, ActivatedRoute } from '@angular/router'

import { ConfiguracoesService } from 'src/app/services/configuracoes/configuracoes.service'

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.css']
})
export class ServicosComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  itensPagina: number[] = [5, 10, 15];
  initialSelection = [];
  allowMultiSelect = true;
  length: number;
  pageSize: number;
  pageIndex: number;
  showFirstLastButtons = true;
  showItens = true;
  search: string;

  displayedColumns: string[] = ['id', 'descricao', 'status', 'acoes']

  protected router: Router
  protected activatedRouter: ActivatedRoute
  protected configuracoesService: ConfiguracoesService

  constructor (private injector: Injector) {
    this.router = this.injector.get(Router)
    this.activatedRouter = this.injector.get(ActivatedRoute)
    this.configuracoesService = this.injector.get(ConfiguracoesService)
  }

  ngOnInit () {
    this.configuracoesService.listaServicos().subscribe((result) => {
      this.dataSource = new MatTableDataSource(result)
      this.dataSource.paginator = this.paginator
      this.length = result.length + 1
      this.pageIndex = 0
    })
  }

  incluirServico () {
    this.router.navigate(['detalhe', 0], { relativeTo: this.activatedRouter })
  }

  alterarServico (id: number) {
    this.router.navigate(['detalhe', id], { relativeTo: this.activatedRouter })
  }

  handlePageEvent (event: PageEvent) {
    this.length = event.length + 1
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
  }

  selectedMenu (url: string) {
    this.router.navigate([`/${url}`])
  }

  applyFilter (event: Event) {
    const value = (event.target as HTMLInputElement).value
    this.dataSource.filter = value.trim().toLowerCase()
  }

  onBack (): void {
    this.router.navigate(['home'])
  }
}
