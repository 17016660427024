import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { MatTableDataSource } from '@angular/material/table'
import { map } from 'rxjs'

import { IDocenteModel } from 'src/app/Models/docente/docente.model'

import { PromptMessageService } from 'src/app/services/modal/prompt-message.service'
import { IDocenteUpdateStatusModel } from 'src/app/Models/docente/docente.update-status'
import { DocenteService } from 'src/app/services/cadastros/docente.service'
import { MatSidenav } from '@angular/material/sidenav'

@Component({
  selector: 'app-manutencao',
  templateUrl: './manutencao.component.html',
  styleUrls: ['./manutencao.component.css']
})
export class ManutencaoComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav
  displayedColumns: string[] = ['id', 'rf', 'nome', 'ativo']
  dataSource: MatTableDataSource<IDocenteModel>;
  resultDocenteModel: IDocenteUpdateStatusModel[] = [];
  search: string;
  multiSelect: boolean = true;
  constructor (private docenteService: DocenteService,
                private _router: Router,
                private activatedRouter: ActivatedRoute,
                private messageService: PromptMessageService
  ) { }

  ngOnInit (): void {
    this.docenteService.listaManutencao().pipe(map((result) => {
      this.dataSource = new MatTableDataSource<IDocenteModel>(result)
    })).subscribe()
  }

  applyFilter (event: Event) {
    const value = (event.target as HTMLInputElement).value
    this.dataSource.filter = value.trim().toLowerCase()
  }

  updateStatus (element) {
    element.ativo = !element.ativo
    const indexArr = this.resultDocenteModel.findIndex(x => x.idProfessor === element.id)
    if (indexArr > -1) {
      this.resultDocenteModel[indexArr].status = element.ativo
    } else {
      this.resultDocenteModel.push({ idProfessor: element.id, status: element.ativo } as IDocenteUpdateStatusModel)
    }
    /*
      if (element.ativo) {
        this.dataSource.data.forEach(row => this.selection.select(element))
      } else {
        this.dataSource.data.forEach(row => this.selection.deselect(element))      }
    */
  }

  validaStatus (element): string {
    return element.ativo ? 'Sim' : 'Não'
  }

  salvar () {
    if (this.resultDocenteModel && this.resultDocenteModel.length > 0) {
      this.docenteService.updateStatus(this.resultDocenteModel).subscribe((p: boolean) => {
        if (p) {
          this.messageService.openDialog('Sucesso', 'Alterações realizadas com sucesso.')
        } else {
          this.messageService.openDialog('Atenção', 'Não foi possível realizar a alteração solicitada.')
        }
      })
    } else {
      this.messageService.openDialog('Atenção', 'Nenhum cadastro foi alterado')
    }
    this.reset()
  }
  /*
  masterToggle () {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row))
  }

  isAllSelected (): boolean {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }
  */

  validChange (event: any, selectElement: any) {
    return event ? selectElement : null
  }

  reset () {
    this.ngOnInit()
    this.resultDocenteModel = []
    this._router.navigate(['cadastros', 'professor'])
  }
}
