<mat-sidenav-container>
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content>
    <div class="container content mat-elevation-z4">
      <h2>{{ title }}</h2>
      <div>
        <form [formGroup]="frmDetail">
          <div class="container">
            <div class="column">
              <br />
              <mat-form-field appearance="fill">
                <mat-label>Id</mat-label>
                <input matInput formControlName="id" placeholder="id" readonly="readOnly" />
              </mat-form-field>
            </div>
            <div class="column">
              <mat-form-field appearance="fill">
                <mat-label>Turno</mat-label>
                <input matInput formControlName="turno" placeholder="turno" />
              </mat-form-field>
            </div>
            <div class="column">
              <mat-form-field appearance="fill">
                <mat-label>Sigla</mat-label>
                <input matInput formControlName="sigla" placeholder="sigla" />
              </mat-form-field>
            </div>
            <div class="column">
              <mat-form-field appearance="fill">
                <mat-label>Horário</mat-label>
                <input matInput formControlName="horario" placeholder="horario" />
              </mat-form-field>
            </div>
            <div class="column">
              <mat-slide-toggle formControlName="ativo" (change)="changed()">
                {{ changed() }}
              </mat-slide-toggle>
            </div>
            <div>
              <button mat-raised-button color="warn">Cancelar</button>
              <button mat-raised-button color="primary" (click)="atualiza()">
                Confirmar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-sidenav-content>
  <mat-sidenav-content style="background-color: rgb(240, 232, 128);">
    <ul><b>Descrição dos turnos</b>
      <li>M: manhã </li>
      <li>C: contra-turno</li>
      <li>T: tarde</li>
    </ul>
  </mat-sidenav-content>
</mat-sidenav-container>
