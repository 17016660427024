<div *ngIf="showMenu" class="div-toolbar">
  <mat-toolbar color="primary">
    <span class="span-title">SIGD Sistema de Gestão de Documentos</span>
    <span class="toolbar-spacer"></span>
    <button mat-button class="menu-button" (click)="selectedService('login')">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sair</span>
    </button>
  </mat-toolbar>
  <div>
    <div fxShow="true" fxHide.gt-sm="true">
      <button mat-button class="menu-button" (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <mat-sidenav-container fxFlexFill class="example-container">
      <mat-sidenav #sidenav fxLayout="column">
        <div fxLayout="column">
          <a role="button" mat-button (click)="selectedService('home')">Serviços</a>
          <a role="button" mat-button (click)="selectedService('cadastros')" mat-button>Cadastros</a>
          <a role="button" mat-button (click)="selectedService('configuracoes')" mat-button>Configurações</a>
          <button mat-button class="menu-button" (click)="selectedService('login')">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sair</span>
          </button>
        </div>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>
</div>
<app-loading></app-loading>
<router-outlet></router-outlet>
