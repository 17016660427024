<form>
  <h2>Atenção! É necessário cadastrar uma nova senha para realizar o login</h2>
  <div>
    <mat-form-field>
      <mat-label>senha atual</mat-label>
      <input matInput type="password" placeholder="senha atual" [(ngModel)]="senhaAtual">
      <mat-error>digite a senha atual</mat-error>
    </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>nova senha</mat-label>
        <input matInput type="password" placeholder="nova senha" [(ngModel)]="senhaNova">
        <mat-error>digite a senha nova</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>confirme a nova senha</mat-label>
        <input matInput type="password" placeholder="confirme a nova senha" [(ngModel)]="senhaNovaConfirmacao">
        <mat-error>confirme a senha digitada</mat-error>
      </mat-form-field>
    </div>
  <div>
    <button mat-button class="button" (click)="onClick()" mat-raised-button color="primary">confirmar</button>
    <button mat-button class="button-forget-password" (click)="onCloseClick()">cancelar</button>
  </div>
</form>
