<mat-sidenav-container>
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content>
    <div class="container content mat-elevation-z4">
      <br />
      <h2>{{ title }}</h2>
      <form [formGroup]="frmDetail">
        <div class="container">
          <div class="column">
            <br />
            <mat-form-field appearance="fill">
              <mat-label>Id</mat-label>
              <input
                matInput
                formControlName="idPerfil"
                placeholder="Id perfil"
                readonly="readOnly"
              />
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field appearance="fill">
              <mat-label>Descrição</mat-label>
              <input
                matInput
                formControlName="descricao"
                placeholder="descrição"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="column">
          <mat-slide-toggle formControlName="status" (change)="onChange()">
            {{ status }}
          </mat-slide-toggle>
        </div>
        <div>
          <button mat-raised-button color="warn">Cancelar</button>
          <button mat-raised-button color="primary" (click)="atualiza()">
            Confirmar
          </button>
        </div>
      </form>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
