import { DatePipe } from '@angular/common'
import { Component, Injector, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ITurnoHorarioModel } from 'src/app/Models/cadastro/turno-horario.model'
import { TurnoHorarioService } from 'src/app/services/cadastros/turno-horario.service'
import { PromptMessageService } from 'src/app/services/modal/prompt-message.service'

@Component({
  selector: 'app-turno-horario-detalhes',
  templateUrl: './turno-horario-detalhes.component.html',
  styleUrls: ['./turno-horario-detalhes.component.css']
})
export class TurnoHorarioDetalhesComponent implements OnInit {
  id: number
  turnoHorarioModel: ITurnoHorarioModel
  status: string
  title: string
  protected activatedRoute: ActivatedRoute
  protected turnoHorarioService: TurnoHorarioService
  protected message: PromptMessageService
  public frmDetail: FormGroup
  readOnly = true
  datePipe = new DatePipe('pt-BR');

  constructor (private injector: Injector) {
    this.activatedRoute = this.injector.get(ActivatedRoute)
    this.turnoHorarioService = this.injector.get(TurnoHorarioService)
    this.message = this.injector.get(PromptMessageService)
    this.frmDetail = this.injector.get(FormBuilder).group({
      id: [null],
      turno: [null, Validators.required],
      horario: [null],
      sigla: [null, Validators.required],
      ativo: [null]
    })
  }

  ngOnInit () {
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'))
    if (this.id !== 0) {
      this.title = 'Alteração de turno horário'
      this.turnoHorarioService.detalhe(this.id).subscribe((result) => {
        this.turnoHorarioModel = result
        this.setFrmDetails()
      })
    } else {
      this.title = 'Inclusão de turno horário'
      this.turnoHorarioModel = {
        id: 0,
        turno: '',
        horario: '',
        sigla: '',
        ativo: true
      }
      this.setFrmDetails()
    }
  }

  atualiza () {
    if (this.turnoHorarioModel.id !== 0) {
      if (!this.validChange()) {
        this.message.openDialog('Alteração', 'Dados inválidos verifique os campos obrigatórios')
      } else {
        this.setTurnoHorarioModel()
        this.turnoHorarioService.update(this.turnoHorarioModel).subscribe((result: any) => {
          this.message.openDialog('Alteração', result ? 'Alteração realizada com sucesso' : 'Não foi possível realizar a alteração')
        }
        )
      }
    } else {
      this.novo()
    }
  }

  novo () {
    if (!this.validInsert()) {
      this.message.openDialog('Inclusão', 'Dados inválidos verifique os campos obrigatórios')
    } else {
      this.setTurnoHorarioModel()
      this.turnoHorarioModel.id = 0
      this.turnoHorarioService.insert(this.turnoHorarioModel).subscribe((result: any) => {
        this.message.openDialog('Inclusão', result ? 'Inclusão realizada com sucesso' : 'Não foi possível realizar a inclusão')
      })
    }
  }

  setTurnoHorarioModel () {
    this.turnoHorarioModel.id = this.frmDetail.get('id').value
    this.turnoHorarioModel.turno = this.frmDetail.get('turno').value
    this.turnoHorarioModel.horario = this.frmDetail.get('horario').value
    this.turnoHorarioModel.sigla = this.frmDetail.get('sigla').value
    this.turnoHorarioModel.ativo = this.frmDetail.get('ativo').value
  }

  setFrmDetails () {
    this.frmDetail.get('id').setValue(this.turnoHorarioModel.id)
    this.frmDetail.get('turno').setValue(this.turnoHorarioModel.turno)
    this.frmDetail.get('horario').setValue(this.turnoHorarioModel.horario)
    this.frmDetail.get('sigla').setValue(this.turnoHorarioModel.sigla)
    this.frmDetail.get('ativo').setValue(this.turnoHorarioModel.ativo)
  }

  validChange () {
    return this.frmDetail.get('turno').value !== this.turnoHorarioModel.turno ||
           this.frmDetail.get('horario').value !== this.turnoHorarioModel.horario ||
           this.frmDetail.get('sigla').value !== this.turnoHorarioModel.sigla ||
           this.frmDetail.get('ativo').value !== this.turnoHorarioModel.ativo
  }

  validInsert () {
    return this.frmDetail.get('turno').value !== '' &&
           this.frmDetail.get('horario').value !== null &&
           this.frmDetail.get('sigla').value !== null
  }

  changed (): string {
    this.status = this.frmDetail.get('ativo').value ? 'Ativado' : 'Desativado'
    return this.status
  }
}
