import { Component, Injector, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSidenav } from '@angular/material/sidenav'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'

import { IClasseTurmaModel } from './../../Models/cadastro/classe-turma.model'
import { ClasseTurmaService } from './../../services/cadastros/classe-turma.service'

@Component({
  selector: 'app-classe-turma',
  templateUrl: './classe-turma.component.html',
  styleUrls: ['./classe-turma.component.css']
})
export class ClasseTurmaComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  itensPagina: number[] = [5, 10, 15];
  initialSelection = [];
  allowMultiSelect = true;
  length: number;
  pageSize: number;
  pageIndex: number;
  showFirstLastButtons = true;
  showItens = true;
  search: string;

  public cargo$ = new Observable<IClasseTurmaModel[]>();

  displayedColumns: string[] = ['id', 'classe', 'turma', 'status', 'acoes']

  protected router: Router
  protected activatedRouter: ActivatedRoute
  protected classeTurmaService: ClasseTurmaService

  constructor (private injector: Injector) {
    this.router = this.injector.get(Router)
    this.activatedRouter = this.injector.get(ActivatedRoute)
    this.classeTurmaService = this.injector.get(ClasseTurmaService)
  }

  ngOnInit () {
    this.classeTurmaService.listaClasseTurma().subscribe((result) => {
      result.forEach(p => {
        p.status = p.ativo ? 'Ativo' : 'Desativado'
      })
      this.dataSource = new MatTableDataSource(result)
      this.dataSource.paginator = this.paginator
      this.length = result.length + 1
      this.pageIndex = 0
    })
  }

  incluirTurma () {
    this.router.navigate(['detalhe', 0], { relativeTo: this.activatedRouter })
  }

  alterarTurma (id: number) {
    this.router.navigate(['detalhe', id], { relativeTo: this.activatedRouter })
  }

  handlePageEvent (event: PageEvent) {
    this.length = event.length + 1
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
  }

  selectedMenu (url: string) {
    this.router.navigate([`/${url}`])
  }

  applyFilter (event: Event) {
    const value = (event.target as HTMLInputElement).value
    this.dataSource.filter = value.trim().toLowerCase()
  }

  onBack (): void {
    this.router.navigate(['home'])
  }
}
