/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { HttpCustomClient } from 'src/app/shared/HttpClient/http-custom-client'
import { HTTPURLS } from '../../shared/HttpClient/http-urls.enum'
import { ITurnoHorarioModel } from 'src/app/Models/cadastro/turno-horario.model'
import { TurnoHorarioMock } from '../mocks/TurnoHorarioMock'
import { DocenteService } from './docente.service'
import { ICargoEspecialidadeModel } from 'src/app/Models/cadastro/cargo-especialidade.model'
import { ICargoModel } from 'src/app/Models/cadastro/cargo.model'
import { IClasseTurmaModel } from 'src/app/Models/cadastro/classe-turma.model'
import { IJornadaModel } from 'src/app/Models/cadastro/jornada.model'
import { IPadraoModel } from 'src/app/Models/cadastro/padrao.model'
import { ICategoriaModel } from 'src/app/Models/cadastro/categoria.model'

@Injectable({
  providedIn: 'root'
})
export class CadastroService {
  constructor (private httpClient: HttpCustomClient, private docenteService: DocenteService) { }

  listaTurnoHorario (): Observable<ITurnoHorarioModel[]> {
    return this.httpClient.Get(HTTPURLS.TURNOHORARIO).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as ITurnoHorarioModel[]
      } else {
        [{}] as ITurnoHorarioModel[]
      }
    }))
  }

  listaEspecialidade (): Observable<ICargoEspecialidadeModel[]> {
    return this.httpClient.Get(HTTPURLS.ESPECIALIDADE).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as ICargoEspecialidadeModel[]
      } else {
        [{}] as ICargoEspecialidadeModel[]
      }
    }))
  }

  listaCargo (): Observable<ICargoModel[]> {
    return this.httpClient.Get(HTTPURLS.CARGO).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as ICargoModel[]
      } else {
        [{}] as ICargoModel[]
      }
    }))
  }

  listaClasseTurma (): Observable<IClasseTurmaModel[]> {
    return this.httpClient.Get(HTTPURLS.CLASSETURMA).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as IClasseTurmaModel[]
      } else {
        [{}] as IClasseTurmaModel[]
      }
    }))
  }

  listaJornada (): Observable<IJornadaModel[]> {
    return this.httpClient.Get(HTTPURLS.JORNADA).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as IJornadaModel[]
      } else {
        [{}] as IJornadaModel[]
      }
    }))
  }

  listaPadrao (): Observable<IPadraoModel[]> {
    return this.httpClient.Get(HTTPURLS.PADRAO).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as IPadraoModel[]
      } else {
        [{}] as IPadraoModel[]
      }
    }))
  }

  listaCategoria (): Observable<ICategoriaModel[]> {
    return this.httpClient.Get(HTTPURLS.CATEGORIA).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as ICategoriaModel[]
      } else {
        [{}] as ICategoriaModel[]
      }
    }))
  }

  listaMock (): ITurnoHorarioModel[] {
    return new TurnoHorarioMock().turnoMock()
  }
}
