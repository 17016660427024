import { Component, Injector, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { MatTableDataSource } from '@angular/material/table'

import { IPerfilModel } from 'src/app/Models/configuracoes/perfil.model'
import { ConfiguracoesService } from 'src/app/services/configuracoes/configuracoes.service'
import { IServicoModel } from 'src/app/Models/configuracoes/servico.model'
import { PromptMessageService } from './../../../services/modal/prompt-message.service'
import { map, switchMap } from 'rxjs'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'

@Component({
  selector: 'app-servico-detalhe',
  templateUrl: './servico-detalhe.component.html',
  styleUrls: ['./servico-detalhe.component.css']
})
export class ServicoDetalheComponent implements OnInit {
  idServico: string
  servicoModel: IServicoModel
  perfilModel: IPerfilModel[]
  status: string
  title: string
  perfils: IPerfilModel[]
  protected activatedRoute: ActivatedRoute
  protected configuracoesService: ConfiguracoesService
  protected promptMessage: PromptMessageService
  public frmDetail: FormGroup
  readOnly = true
  changePerfil = false
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'descricao', 'status']

  constructor (private injector: Injector) {
    this.activatedRoute = this.injector.get(ActivatedRoute)
    this.configuracoesService = this.injector.get(ConfiguracoesService)
    this.promptMessage = this.injector.get(PromptMessageService)
    this.frmDetail = this.injector.get(FormBuilder).group({
      idServico: [null],
      descricao: [null, Validators.required],
      status: [null],
      formControlPerfil: [null]
    })
  }

  ngOnInit () {
    this.idServico = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.idServico !== '0') {
      this.title = 'Alteração do serviço cadastrado'
      this.configuracoesService.selectServico(parseInt(this.idServico))
        .subscribe((result: any) => {
          this.servicoModel = result
          this.perfilModel = result.perfils
          this.setFrmDetails()
          this.setPerfilModel()
        })
    } else {
      this.title = 'Inclusão de serviço'
      this.servicoModel = {
        id: 0,
        descricao: '',
        status: true
      }
      this.setPerfilModel()
    }
  }

  atualiza () {
    if (this.servicoModel.id !== 0) {
      if (!this.validChange()) {
        this.promptMessage.openDialog('Alteraçao', 'Não houve alteração de dados')
      } else {
        this.setServicoModel()
        this.configuracoesService.updateServico(this.servicoModel).subscribe((result) => {
          if (!result.success) {
            this.promptMessage.openDialog('Alteraçao', 'Não foi possível atualizar o cadastro do serviço.')
          } else {
            this.promptMessage.openDialog('Alteraçao', 'Dados atualizados com sucesso.')
          }
        }
        )
      }
    } else {
      this.novo()
    }
  }

  novo () {
    if (!this.validInsert()) {
      this.promptMessage.openDialog('Alteraçao', 'Dados inválidos verifique os campos obrigatórios.')
    } else {
      this.setServicoModel()
      this.servicoModel.id = 0
      this.configuracoesService.insertServico(this.servicoModel).subscribe((result) => {
        if (result) {
          this.promptMessage.openDialog('Inclusão', 'Serviço cadastrado com sucesso.')
        } else {
          this.promptMessage.openDialog('Inclusão', 'Não foi possível cadastrar um novo serviço.')
        }
      })
    }
  }

  setServicoModel () {
    this.servicoModel.id = this.frmDetail.get('idServico').value
    this.servicoModel.descricao = this.frmDetail.get('descricao').value
    this.servicoModel.status = this.frmDetail.get('status').value || false
    this.servicoModel.perfils = this.dataSource.data.filter((p: IPerfilModel) => p.status === true)
  }

  setFrmDetails () {
    this.frmDetail.get('idServico').setValue(this.servicoModel.id)
    this.frmDetail.get('descricao').setValue(this.servicoModel.descricao)
    this.frmDetail.get('status').setValue(this.servicoModel.status)
    this.status = this.servicoModel.status ? 'Ativado' : 'Desativado'
    this.perfils = this.servicoModel.perfils
  }

  setPerfilModel () {
    this.configuracoesService.listaPerfil().subscribe((result: IPerfilModel[]) => {
      result.forEach((p) => {
        if (this.perfilModel && this.perfilModel.length > 0) {
          const index = this.perfilModel.findIndex(perfil => perfil.id === p.id)
          if (index !== -1) {
            p.status = this.perfilModel[index].status
          } else {
            p.status = false
          }
        }
      })
      this.frmDetail.get('formControlPerfil').setValue(result)
      this.dataSource = new MatTableDataSource(result)
    })
  }

  validChange () {
    return this.frmDetail.get('descricao').value !== this.servicoModel.descricao ||
           this.frmDetail.get('status').value !== this.servicoModel.status ||
           this.changePerfil
  }

  validInsert () {
    return this.frmDetail.get('descricao').value !== '' &&
           this.frmDetail.get('descricao').value !== null
  }

  onChange () {
    this.status = this.frmDetail.get('status').value ? 'Ativado' : 'Desativado'
  }

  onChangePerfil (event: MatSlideToggleChange, value: any) {
    this.dataSource.data.forEach((p:any) => {
      if (p.id === value.id) {
        p.status = event.checked
        this.changePerfil = true
      }
    })
  }
}
