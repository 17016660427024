/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { HttpCustomClient } from 'src/app/shared/HttpClient/http-custom-client'
import { HTTPURLS } from '../../shared/HttpClient/http-urls.enum'
import { ICargoModel } from 'src/app/Models/cadastro/cargo.model'
import { CargoMock } from '../mocks/CargoMock'

@Injectable({
  providedIn: 'root'
})
export class CargoService {
  constructor (private httpClient: HttpCustomClient) { }

  selectCargo (idCargo: string): Observable<ICargoModel> {
    return this.httpClient.Get(HTTPURLS.CONSULTACARGO, [{ name: 'id', value: idCargo }]).pipe(map((res: IResponseModel) => {
      return res.data as ICargoModel
    }))
  }

  lista (): Observable<ICargoModel[]> {
    return this.httpClient.Get(HTTPURLS.CARGO).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as ICargoModel[]
      } else {
        [{}] as ICargoModel[]
      }
    }))
  }

  atualizaCargo (cargo: ICargoModel): Observable<string> {
    return this.httpClient.Put(HTTPURLS.ATUALIZACARGO, cargo).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.message
      } else {
        return ''
      }
    }))
  }

  novoCargo (cargo: ICargoModel): Observable<boolean> {
    return this.httpClient.Post(HTTPURLS.NOVOCARGO, cargo).pipe(map((res: IResponseModel) => {
      return res.success
    }))
  }

  listaMock (): ICargoModel[] {
    return new CargoMock().cargoMock()
  }
}
