import { ITurnoHorarioModel } from 'src/app/Models/cadastro/turno-horario.model'

export class TurnoHorarioMock {
  turnoMock (): ITurnoHorarioModel[] {
    return [
      { id:1,horario:'7h00 as 7h:45',turno:'M',sigla:'1M' }
      , { id:2,horario:'7h45 as 8h:30',turno:'M',sigla:'2M' }
      , { id:3,horario:'8h30 as 9h:15',turno:'M',sigla:'3M' }
      , { id:4,horario:'9h15 as 10h00',turno:'M',sigla:'4M' }
      , { id:5,horario:'10h00 as 10h:45',turno:'M',sigla:'5M' }
      , { id:6,horario:'10h20 as 11h:05',turno:'M',sigla:'5M2' }
      , { id:7,horario:'11h05 as 11h:50',turno:'M',sigla:'6M' }
      , { id:8,horario:'12h00 as 12h:45',turno:'C',sigla:'1C' }
      , { id:9,horario:'12h45 as 13h:30',turno:'C',sigla:'2C' }
      , { id:10,horario:'13h30 as 14h:15',turno:'T',sigla:'1T' }
      , { id:10,horario:'13h30 as 14h:15',turno:'T',sigla:'1T' }
      , { id:11,horario:'14h15 as 15h:00',turno:'T',sigla:'2T' }
      , { id:12,horario:'15h00 as 15h:45',turno:'T',sigla:'3T' }
      , { id:13,horario:'15h45 as 16h:30',turno:'T',sigla:'4T' }
      , { id:14,horario:'16h30 as 17h:15',turno:'T',sigla:'5T' }
      , { id:15,horario:'16h50 as 17h:35',turno:'T',sigla:'5T2' }
      , { id:16,horario:'17h35 as 18h:20',turno:'T',sigla:'6T' }
    ] as ITurnoHorarioModel[]
  }
}
