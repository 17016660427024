<div>
  <form [formGroup]="rangeFormGroup">
    <div class="form-elements">
      <div class="form-field-data">
        <mat-form-field appearance="fill">
          <mat-label>Selecione um período</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Data inicial"
              formControlName="start"
            />
            <input matEndDate placeholder="Data final" formControlName="end" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error>Campo obrigatório</mat-error>
        </mat-form-field>
        <button mat-icon-button color="accent" (click)="selectPeriod()">
          Ok
        <mat-icon>check</mat-icon>
      </button>
      </div>
    </div>
  </form>
</div>
