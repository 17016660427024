<mat-sidenav-container>
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content>
    <h2>Lista de cargos</h2>
    <h4>Inclusão e alteração do cadastro de cargos</h4>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Pesquisar</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="search"
            (keyup)="applyFilter($event)"
          />
          <button
            matSuffix
            mat-icon-button
            aria-label="search"
            (click)="search = ''"
          >
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
        <div class="col-button">
          <button mat-raised-button color="primary" (click)="incluirCargo()">
            Incluir
          </button>
        </div>
    <div class="table">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="descricao">
          <th mat-header-cell *matHeaderCellDef>Descrição</th>
          <td mat-cell *matCellDef="let element">
            {{ element.descricao }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element.status }}
          </td>
        </ng-container>
        <ng-container matColumnDef="acoes">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let element">
            <button
              matSuffix
              mat-icon-button
              aria-label="alterar"
              (click)="alterarCargo(element.id)"
            >
              <mat-icon style="color: red">edit</mat-icon>
              <span>Alterar</span>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="paginator">
      <mat-paginator
        (page)="handlePageEvent($event)"
        [length]="length"
        [pageSize]="pageSize"
        [showFirstLastButtons]="true"
        [pageSizeOptions]="itensPagina"
        [pageIndex]="pageIndex"
        aria-label="Selecione a pagina"
      >
      </mat-paginator>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
