<mat-sidenav-container>
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content>
    <div class="hora-atividade">
      <h2>Emissão de hora atividade</h2>
      <div>
        <form [formGroup]="rangeFormGroup">
          <div class="form-elements">
            <div class="form-field-data">
              <mat-form-field appearance="fill">
                <mat-label>Selecione um período</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input
                    matStartDate
                    placeholder="Data inicial"
                    formControlName="start"
                  />
                  <input
                    matEndDate
                    placeholder="Data final"
                    formControlName="end"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error>Campo obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="form-field-search">
              <mat-form-field appearance="fill">
                <mat-label>Pesquisa</mat-label>
                <input
                  #search
                  matInput
                  type="search"
                  placeholder="filtra pelo nome"
                />
              </mat-form-field>
              <button
                mat-icon-button
                color="accent"
                (click)="applyFilter(search.value)"
              >
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="table-container">
        <div>
          <div *ngIf="docenteSelecionado()">
            <button
              class="btnBuildFile"
              mat-icon-button
              color="primary"
              (click)="gerarArquivoHoraAtividade()"
            >
              <mat-icon aria-hidden="false" aria-label="btn">download</mat-icon>
              Clique aqui para gerar o(s) arquivo(s) de horário aula
            </button>
            <div *ngIf="percentDownload > 0">
              <mat-progress-bar
                mode="determinate"
                value="{{ percentDownload }}"
              >
                aguarde...
              </mat-progress-bar>
            </div>
          </div>
        </div>
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="selecione">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
                Selecione H.A.
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="rf">
            <th mat-header-cell *matHeaderCellDef>RF</th>
            <td mat-cell *matCellDef="let element">{{ element.rf }}</td>
          </ng-container>

          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef>Professor</th>
            <td mat-cell *matCellDef="let element">{{ element.nome }}</td>
          </ng-container>

          <ng-container matColumnDef="especialidade">
            <th mat-header-cell *matHeaderCellDef>Especialidade</th>
            <td mat-cell *matCellDef="let element">
              {{ element.especialidade }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="paginator">
        <mat-paginator
          (page)="handlePageEvent($event)"
          [length]="length"
          [pageSize]="pageSize"
          [showFirstLastButtons]="true"
          [pageSizeOptions]="itensPagina"
          [pageIndex]="pageIndex"
          aria-label="Selecione a pagina"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
