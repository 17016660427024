<mat-sidenav-container>
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content style="overflow: hidden;">
    <mat-toolbar>
      <mat-toolbar-row>
        <span>Manutenção do cadastro de professores</span>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <mat-form-field class="search">
          <mat-label>Pesquisar</mat-label>
          <input matInput type="text" [(ngModel)]="search" (keyup)="applyFilter($event)" />
          <button matSuffix mat-icon-button aria-label="search" (click)="search = ''">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
        <button class="button-update" (click)="salvar()">Salvar
          <mat-icon color="primary">save</mat-icon>
        </button>
        <button class="button-cancel" (click)="reset()">Cancelar
          <mat-icon color="accent">cancel</mat-icon>
        </button>

      </mat-toolbar-row>
    </mat-toolbar>
  </mat-sidenav-content>
  <mat-sidenav-content>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox  (change)="$event ? masterToggle() : null"
                             [checked]="selection.hasValue() && isAllSelected()"
                             [indeterminate]="selection.hasValue() && !isAllSelected()">
                             Selecione
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="validChange($event,element.select)"
                [checked]="selection.isSelected(element)">
              </mat-checkbox>
            </td>
          </ng-container>
          -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="rf">
          <th mat-header-cell *matHeaderCellDef>R.F.</th>
          <td mat-cell *matCellDef="let element">{{ element.rf }}</td>
        </ng-container>

        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef>Professor</th>
          <td mat-cell *matCellDef="let element">{{ element.nome }}</td>
        </ng-container>

        <ng-container matColumnDef="especialidade">
          <th mat-header-cell *matHeaderCellDef>Especialidade</th>
          <td mat-cell *matCellDef="let element">
            {{ element.especialidade }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ativo">
          <th mat-header-cell *matHeaderCellDef>Cadastro ativo</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [checked]="element.ativo"
              (change)="updateStatus(element)">{{validaStatus(element)}}</mat-slide-toggle>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-sidenav-content>
      <hr>
      <mat-card>
          <mat-card-header>
            <mat-card-title>Atenção</mat-card-title>
            <mat-card-subtitle>manutenção de cadastros</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>
              Não se esqueça de clicar no botão <b>Salvar</b> para confirmar a manutenção realizada.
            </p>
          </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-content>
</mat-sidenav-container>
