import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { SelectionModel } from '@angular/cdk/collections'
import { MatTableDataSource } from '@angular/material/table'

import { map } from 'rxjs'

import { IDocenteModel } from 'src/app/Models/docente/docente.model'
import { DocenteService } from 'src/app/services/cadastros/docente.service'

@Component({
  selector: 'docente',
  templateUrl: './docente.component.html',
  styleUrls: ['./docente.component.css']
})
export class DocenteComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['id', 'rf', 'nome', 'especialidade']
  dataSource: MatTableDataSource<IDocenteModel>;
  itensPagina: number[] = [5, 10, 15];
  initialSelection = [];
  allowMultiSelect = true;
  selection: SelectionModel<IDocenteModel>;
  length: number;
  pageSize: number;
  pageIndex: number;
  showFirstLastButtons = true;
  search: string;

  constructor (private docenteService: DocenteService,
                private _router: Router,
                private activatedRouter: ActivatedRoute
  ) { }

  ngOnInit (): void {
    this.docenteService.lista().pipe(map((result) => {
      this.dataSource = new MatTableDataSource<IDocenteModel>(result)
      this.dataSource.paginator = this.paginator
      this.length = result.length + 1
      this.pageIndex = 0
    })).subscribe()
  }

  handlePageEvent (event: PageEvent) {
    this.length = event.length + 1
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
  }

  detalheDocente (docente: IDocenteModel) {
    this._router.navigate(['detalhe', docente.idEspecialidade], { relativeTo: this.activatedRouter })
  }

  incluirDocente () {
    this._router.navigate(['detalhe', 0], { relativeTo: this.activatedRouter })
  }

  manutencaoDocente () {
    this._router.navigate(['manutencao'], { relativeTo: this.activatedRouter })
  }

  applyFilter (event: Event) {
    const value = (event.target as HTMLInputElement).value
    this.dataSource.filter = value.trim().toLowerCase()
  }
}
