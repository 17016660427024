import { INavItem } from './nav-item'

export const menu: INavItem[] = [
  {
    displayName: 'home',
    routeActivated: 'home',
    route: 'home'
  },
  {
    displayName: 'cadastros',
    routeActivated: 'cadastro',
    route: 'cadastros',
    children: [
      {
        displayName: 'cargos',
        routeActivated: 'cadastros/cargos',
        route: 'cadastros/cargos'
      },
      {
        displayName: 'detalhe',
        routeActivated: 'cadastros/cargos/detalhe',
        route: 'cadastros/cargos/detalhe/{id}'
      },
      {
        displayName: 'jornada',
        routeActivated: 'cadastros/jornada',
        route: 'cadastros/jornada'
      },
      {
        displayName: 'detalhe',
        routeActivated: 'cadastros/jornada/detalhe',
        route: 'cadastros/jornada/detalhe/{id}'
      },
      {
        displayName: 'padrao',
        routeActivated: 'cadastros/padrao',
        route: 'cadastros/padrao'
      },
      {
        displayName: 'detalhe',
        routeActivated: 'cadastros/padrao/detalhe',
        route: 'cadastros/padrao/detalhe/{id}'
      },
      {
        displayName: 'professor',
        routeActivated: 'cadastros/professor',
        route: 'cadastros/professor'
      },
      {
        displayName: 'manutencao',
        routeActivated: 'cadastros/professor/manutencao',
        route: 'cadastros/professor/manutencao'
      },
      {
        displayName: 'detalhe',
        routeActivated: 'cadastros/professor/detalhe',
        route: 'cadastros/professor/detalhe/{id}'
      },
      {
        displayName: 'classe-turma',
        routeActivated: 'cadastros/classe-turma',
        route: 'cadastros/classe-turma'
      },
      {
        displayName: 'detalhe',
        routeActivated: 'cadastros/classe-turma/detalhe',
        route: 'cadastros/classe-turma/detalhe/{id}'
      },
      {
        displayName: 'turno-horario',
        routeActivated: 'cadastros/turno-horario',
        route: 'cadastros/turno-horario'
      },
      {
        displayName: 'detalhe',
        routeActivated: 'cadastros/turno-horario/detalhe',
        route: 'cadastros/turno-horario/detalhe/{id}'
      }
    ] as INavItem[]
  },
  {
    displayName: 'hora-atividade',
    routeActivated: 'hora-atividade',
    route: 'hora-atividade',
  },
  {
    displayName: 'configuracoes',
    routeActivated: 'configuracoes',
    route: 'configuracoes',
    children: [
      {
        displayName: 'perfil',
        routeActivated: 'configuracoes/perfil',
        route: 'configuracoes/perfil'
      },
      {
        displayName: 'detalhe',
        routeActivated: 'configuracoes/perfil/detalhe',
        route: 'configuracoes/perfil/detalhe/{id}'
      },
      {
        displayName: 'usuarios',
        routeActivated: 'configuracoes/usuario',
        route: 'configuracoes/usuario'
      },
      {
        displayName: 'detalhe',
        routeActivated: 'configuracoes/usuario/detalhe',
        route: 'configuracoes/usuario/detalhe/{id}'
      },
      {
        displayName: 'servico',
        routeActivated: 'configuracoes/servico',
        route: 'configuracoes/servico'
      },
      {
        displayName: 'detalhe',
        routeActivated: 'configuracoes/servicos/detalhe',
        route: 'configuracoes/servicos/detalhe/{id}'
      }
    ] as INavItem[]
  }
]
