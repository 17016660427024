/* eslint-disable no-useless-constructor */
import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService } from './services/authentication/authentication.service';

import { SessionStorageService } from './shared/session-storage.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sap';
  showMenu: boolean = false;
  constructor (private router: Router,
               private sessionService: SessionStorageService,
               private authService: AuthenticationService) { }

  nameUser: string

  ngOnInit (): void {
    this.nameUser = this.sessionService.getValue('username')
    this.authService.showMenuEmitter.subscribe(
      (res) => {
        this.showMenu = res
      }
    )
  }

  selectedService (url: string) {
    this.router.navigate([`/${url}`]).then(() => window.location.reload())
  }
}
