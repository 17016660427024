import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { map, Observable } from 'rxjs'

import { ModalDocHtmlComponent } from './../../shared/mat-dialog/modal-doc-html/modal-doc-html.component'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { HttpCustomClient } from 'src/app/shared/HttpClient/http-custom-client'
import { HTTPURLS } from '../../shared/HttpClient/http-urls.enum'
import { IHttpParam } from '../../shared/HttpClient/http-param'
import { IDocenteModel } from 'src/app/Models/docente/docente.model'
import { IDocenteDomain } from 'src/app/Domains/docente/docente.domain'
import { IDocenteDetalheResult } from 'src/app/Models/docente/docente-detalhe.result'
import { IDocenteDetalheDomain } from 'src/app/Domains/docente/docente-detalhe.domain'
import { IDocenteInterfaceService } from './docente-interface.service.ts'
import { IDocenteUpdateStatusModel } from 'src/app/Models/docente/docente.update-status'

@Injectable({
  providedIn: 'root'
})
export class DocenteService implements IDocenteInterfaceService {
  constructor (private httpClient: HttpCustomClient,
               private dialog: MatDialog) { }

  updateDetails (domain: IDocenteDetalheDomain): Observable<IResponseModel> {
    return this.httpClient.Post(HTTPURLS.UPDATEDETAILSDOCENTE, domain).pipe(map((res: IResponseModel) => {
      return res
    }))
  }

  insert (domain: IDocenteDetalheDomain) {
    return this.httpClient.Post(HTTPURLS.INSERTDOCENTE, domain).pipe(map((res: IResponseModel) => {
      return res.message
    }))
  }

  updateHoraAtividade (docente: any): Observable<IResponseModel> {
    return this.httpClient.Post(HTTPURLS.UPDATEHADOCENTE, docente).pipe(map((res: IResponseModel) => {
      return res
    }))
  }

  lista (): Observable<IDocenteModel[]> {
    return this.httpClient.Get(HTTPURLS.DOCENTELISTA).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as IDocenteModel[]
      } else {
        [{}] as IDocenteModel[]
      }
    }))
  }

  pesquisa (nomeDocente: string): Observable<IResponseModel> {
    return this.httpClient.Get(HTTPURLS.DOCENTEPESQUISA, [{ name: 'docente', value: nomeDocente }] as IHttpParam[]).pipe(map((res: IResponseModel) => {
      return res
    }))
  }

  detalheDocente (docenteIdEspecialidade: number): Observable<IDocenteDetalheResult> {
    return this.httpClient.Get(HTTPURLS.DOCENTEDETALHE, [{ name: 'docenteIdEspecialidade', value: docenteIdEspecialidade }] as IHttpParam[])
      .pipe(map((res: IResponseModel) => {
        if (res.success) {
          return res.data as IDocenteDetalheResult
        }
        return {} as IDocenteDetalheResult
      }))
  }

  horaAtividade (docente: IDocenteDomain) {
    const fileName = `hora_atividade_${docente.nomeDocente}`
    return this.httpClient.getFile(HTTPURLS.HORAATIVIDADE, docente, fileName, 'pdf')
  }

  docHoraAtividade (docente: IDocenteDomain) {
    return this.httpClient.Post(HTTPURLS.DOCHORAATIVIDADE, docente).subscribe((resp: IResponseModel) => {
      if (resp.success) {
        this.dialog.open(ModalDocHtmlComponent, {
          width: '800px',
          data: { document: resp.data },
          panelClass: 'custom-container'
        })
      }
    })
  }

  updateStatus (result: IDocenteUpdateStatusModel[]): Observable<boolean> {
    const conteudoRequisicao = { Docentes: result }
    return this.httpClient.Post(HTTPURLS.ATUALIZASTATUSPROFESSOR, conteudoRequisicao).pipe(map((resp: IResponseModel) => {
      return resp.success as boolean
    }))
  }

  listaManutencao (): Observable<IDocenteModel[]> {
    return this.httpClient.Get(HTTPURLS.DOCENTELISTAMANUTENCAO).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as IDocenteModel[]
      } else {
        [{}] as IDocenteModel[]
      }
    }))
  }
}
