    <app-breadcrumb></app-breadcrumb>
    <div class="content mat-elevation-z4">
      <div *ngIf="showItens" class="container">
        <div class="card mat-card-detalhes">
          <mat-card>
            <mat-card-title>{{ title }}</mat-card-title>
            <mat-card-content>
              <div class="container">
                <form [formGroup]="editForm">
                  <div fxLayout="row wrap" fxLayoutGap="20px">
                    <div fxFlex="60">
                      <mat-form-field appearance="fill">
                        <mat-label>Nome</mat-label>
                        <input
                          matInput
                          formControlName="nome"
                          placeholder="nome do professor"
                        />
                        <ng-container *ngIf="editForm.controls.nome.touched">
                          <span *ngIf="editForm.controls.nome.errors?.required">
                            Campo obrigátorio
                          </span>
                        </ng-container>
                      </mat-form-field>
                    </div>
                    <div fxFlex="35">
                      <mat-form-field appearance="fill">
                        <mat-label>R.F.</mat-label>
                        <input
                          matInput
                          formControlName="rf"
                          placeholder="rf do professor"
                        />
                        <ng-container *ngIf="editForm.controls.rf.touched">
                          <span *ngIf="editForm.controls.rf.errors?.required">
                            Campo obrigátorio
                          </span>
                        </ng-container>
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutGap="25px">
                    <div fxFlex="47">
                      <mat-form-field appearance="fill">
                        <mat-label style="font-size: small"
                          >Cargo:
                          {{ docenteDetalhes.cargosDescricao }}</mat-label
                        >
                        <mat-select formControlName="idCargo" multiple>
                          <mat-option
                            *ngFor="let cargo of cargo$ | async"
                            [value]="cargo.id"
                          >
                            {{ cargo.descricao }}
                          </mat-option>
                        </mat-select>
                        <ng-container *ngIf="editForm.controls.idCargo.touched">
                          <span *ngIf="editForm.controls.idCargo.errors?.required">
                            Campo obrigátorio
                          </span>
                        </ng-container>
                      </mat-form-field>
                    </div>
                    <div fxFlex="47">
                      <mat-form-field appearance="fill">
                        <mat-label style="font-size: small"
                          >Especialidade:
                          {{ docenteDetalhes.especialidade }}</mat-label
                        >
                        <mat-select formControlName="idEspecialidade">
                          <mat-option
                            *ngFor="let especialidade of especialidade$ | async"
                            [value]="especialidade.id"
                          >
                            {{ especialidade.descricao }}
                          </mat-option>
                        </mat-select>
                        <ng-container *ngIf="editForm.controls.idEspecialidade.touched">
                          <span *ngIf="editForm.controls.idEspecialidade.errors?.required">
                            Campo obrigátorio
                          </span>
                        </ng-container>
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutGap="25px">
                    <div fxFlex="47">
                      <mat-form-field appearance="fill">
                        <mat-label style="font-size: small"
                          >Jornada: {{ docenteDetalhes.jornada }}</mat-label
                        >
                        <mat-select formControlName="idJornada">
                          <mat-option
                            *ngFor="let jornada of jornada$ | async"
                            [value]="jornada.id"
                          >
                            {{ jornada.codigo }}
                          </mat-option>
                        </mat-select>
                        <ng-container *ngIf="editForm.controls.idJornada.touched">
                          <span *ngIf="editForm.controls.idJornada.errors?.required">
                            Campo obrigátorio
                          </span>
                        </ng-container>
                      </mat-form-field>
                    </div>
                    <div fxFlex="47">
                      <mat-form-field appearance="fill">
                        <mat-label style="font-size: small"
                          >Classe/Turma:
                          {{ docenteDetalhes.classesDescricao }}</mat-label
                        >
                        <mat-select formControlName="idClasse" multiple (selectionChange)="selecionaTurma($event)">
                          <mat-option
                            *ngFor="let classe of classeTurmas$ | async"
                            [value]="classe.id"
                          >
                            {{ classe.serie }}
                          </mat-option>
                        </mat-select>
                        <ng-container *ngIf="editForm.controls.idClasse.touched">
                          <span *ngIf="editForm.controls.idClasse.errors?.required">
                            Campo obrigátorio
                          </span>
                        </ng-container>
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutGap="25px">
                    <div fxFlex="47">
                      <mat-form-field appearance="fill">
                        <mat-label style="font-size: small"
                          >Padrão: {{ docenteDetalhes.padrao }}</mat-label
                        >
                        <mat-select formControlName="idPadrao">
                          <mat-option
                            *ngFor="let padrao of padrao$ | async"
                            [value]="padrao.id"
                          >
                            {{ padrao.codigo }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div fxFlex="47">
                      <mat-form-field appearance="fill">
                        <mat-label style="font-size: small"
                          >Categoria: {{ docenteDetalhes.categoria }}</mat-label
                        >
                        <mat-select formControlName="idCategoria">
                          <mat-option
                            *ngFor="let categoria of categoria$ | async"
                            [value]="categoria.id"
                          >
                            {{ categoria.descricao }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutGap="25px">
                    <mat-slide-toggle formControlName="ativo">Ativo</mat-slide-toggle>
                  </div>
                </form>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <div
                fxLayout="row wrap"
                fxLayoutGap="40px"
                *ngIf="optionsValid()"
              >
                <div fxFlex="20">
                  <button
                    mat-button
                    style="
                      color: red;
                      padding: 10px;
                      margin-left: 20px;
                      background-color: bisque;
                    "
                    (click)="ngOnInit()"
                  >
                    Cancelar
                  </button>
                </div>
                <div fxFlex="20">
                  <button
                    mat-button
                    style="
                      color: blue;
                      padding: 10px;
                      margin-left: 20px;
                      background-color: bisque;
                    "
                    (click)="updateDocente()"
                  >
                    Atualizar
                  </button>
                </div>
                <!--
                <div fxFlex="20">
                  <button
                    mat-button
                    style="
                      color: green;
                      padding: 10px;
                      margin-left: 20px;
                      background-color: bisque;
                    "
                    (click)="gerarArquivoHorarioAula()"
                  >
                    Hora atividade
                  </button>
                </div>
                -->
              </div>
              <div
                fxLayout="row wrap"
                fxLayoutGap="30px"
                *ngIf="!optionsValid()"
              >
                <div fxFlex="50">
                  <button
                    mat-button
                    style="
                      color: red;
                      padding: 10px;
                      margin-left: 40px;
                      background-color: bisque;
                    "
                    (click)="ngOnInit()"
                  >
                    Cancelar
                  </button>
                </div>
                <div fxFlex="30">
                  <button
                    mat-button
                    style="
                      color: green;
                      padding: 10px;
                      margin-left: 80px;
                      background-color: bisque;
                    "
                    (click)="insertDocente()"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
      <div class="mat-card-horario">
        <div class="table">
          <mat-label style="font-size: small">
            Horas Atividade: {{ docenteDetalhes.horariosDescricao }}
          </mat-label>
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="turno">
              <th mat-header-cell *matHeaderCellDef>Turno</th>
              <td mat-cell *matCellDef="let element">{{ element.turno }}</td>
            </ng-container>
            <ng-container matColumnDef="horario">
              <th mat-header-cell *matHeaderCellDef>Horário</th>
              <td mat-cell *matCellDef="let element">
                {{ element.horario }}
              </td>
            </ng-container>
            <ng-container matColumnDef="segunda">
              <th mat-header-cell *matHeaderCellDef>Segunda</th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.segunda"> </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="terca">
              <th mat-header-cell *matHeaderCellDef>Terça</th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.terca"> </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="quarta">
              <th mat-header-cell *matHeaderCellDef>Quarta</th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.quarta"> </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="quinta">
              <th mat-header-cell *matHeaderCellDef>Quinta</th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.quinta"> </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="sexta">
              <th mat-header-cell *matHeaderCellDef>Sexta</th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.sexta"> </mat-checkbox>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <div class="paginator">
          <mat-paginator
            (page)="handlePageEvent($event)"
            [length]="length"
            [pageSize]="pageSize"
            [showFirstLastButtons]="true"
            [pageSizeOptions]="itensPagina"
            [pageIndex]="pageIndex"
            aria-label="Selecione a pagina"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
