<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
  rel="stylesheet"
/>
<div class="container">
  <div class="cad-geral">
      <app-breadcrumb></app-breadcrumb>
    <br />
    <h2>Cadastros</h2>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="33%">
        <mat-card (click)="onSelect('cargos')">
          <mat-icon>assignment_ind</mat-icon>
          <br />
          <mat-card-title>Cargos</mat-card-title>
          <mat-card-subtitle
            >Cadastro de cargos e especialidades</mat-card-subtitle
          >
        </mat-card>
      </div>
      <div fxFlex="33%">
        <mat-card (click)="onSelect('jornada')">
          <mat-icon>group_work</mat-icon>
          <br />
          <mat-card-title>Jornada</mat-card-title>
          <mat-card-subtitle>Cadastro de jornada</mat-card-subtitle>
        </mat-card>
      </div>
      <div fxFlex="33%">
        <mat-card (click)="onSelect('professor')">
          <mat-icon>group</mat-icon>
          <br />
          <mat-card-title>Professores</mat-card-title>
          <mat-card-subtitle>Cadastro de professores</mat-card-subtitle>
        </mat-card>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="33%">
        <mat-card (click)="onSelect('classe-turma')">
          <mat-icon>school</mat-icon>
          <br />
          <mat-card-title>Turma</mat-card-title>
          <mat-card-subtitle>Cadastro de classe e turmas</mat-card-subtitle>
        </mat-card>
      </div>
      <div layoutFlex="33%">
        <mat-card (click)="onSelect('turno-horario')">
          <mat-icon>schedule</mat-icon>
          <br />
          <mat-card-title>Horários</mat-card-title>
          <mat-card-subtitle
            >Cadastro de horários e turnos</mat-card-subtitle
          >
        </mat-card>
      </div>
      <div layoutFlex="33%">
        <mat-card (click)="onSelect('padrao')">
          <mat-icon>assignment_turned_in</mat-icon>
          <br />
          <mat-card-title>Padrão</mat-card-title>
          <mat-card-subtitle>Cadastro de padrão</mat-card-subtitle>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
