import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class UrlService {
  shortenUrlIfNecessary (returnUrl: string): string {
    const questionMark = returnUrl.indexOf('?')

    if (questionMark > -1) {
      returnUrl = returnUrl.substring(0, questionMark)
    }

    return returnUrl
  }

  getQueryParams (returnUrl: string): any {
    const queryParams: any = {}

    const questionMark = returnUrl.indexOf('?')
    if (questionMark > -1) {
      const paramString = returnUrl.substring(questionMark + 1, returnUrl.length)
      const queryMap = this.getMapFromParamString(paramString)
      queryParams.queryParams = queryMap
    }
    return queryParams
  }

  private getMapFromParamString (paramString: string): Map<string, string> {
    const pairs: string[] = paramString.split('&')
    const paramMap = new Map()

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i]
      const map: string[] = pair.split('=')
      const key: string = map[0]
      const val: string = map[1]

      paramMap.set(key, val)
    }
    return paramMap
  }
}
