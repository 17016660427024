/* eslint-disable no-useless-constructor */
import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface DialogData {
  title: string,
  message: string,
  icon: boolean
}

@Component({
  selector: 'app-mat-dialog-custom',
  templateUrl: './mat-dialog-custom.component.html',
  styleUrls: ['./mat-dialog-custom.component.css']
})
export class MatDialogCustomComponent {
  showIcon: boolean
  constructor (public dialogRef: MatDialogRef<MatDialogCustomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.showIcon = data.icon
  }

  onNoClick (): void {
    this.dialogRef.close()
  }
}
