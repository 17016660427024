<div class="icon-message" *ngIf="showIcon">
  <mat-icon>message</mat-icon>
</div>
<h3 mat-dialog-title>{{data.title}}</h3>
<div mat-dialog-content>
  <h4>{{data.message}}</h4>
</div>
<div mat-dialog-actions>
  <button mat-button mat-raised-button color="primary" cdkFocusInitial (click)="onNoClick()">Ok</button>
</div>
