<div class="route-content-top">
  <ol class="breadcrumbs">
    <li *ngFor="let breadcrumb of breadcrumbs; index as i; last as isLast">
      <ng-container *ngIf="!breadcrumb.pauseDisplay">
        <span class="breadcrumb-current-route" [class.breadcrumb-history-link]="!isLast" (click)="routeTo(i)">{{breadcrumb.name}}</span>
        <span *ngIf="!isLast"> » </span>
      </ng-container>
    </li>
  </ol>
  <hr/>
</div>
