/* eslint-disable no-useless-constructor */
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { IResponseModel } from 'src/app/Models/IResponseModel'
import { HttpCustomClient } from 'src/app/shared/HttpClient/http-custom-client'
import { HTTPURLS } from '../../shared/HttpClient/http-urls.enum'
import { IClasseTurmaModel } from 'src/app/Models/cadastro/classe-turma.model'

@Injectable({
  providedIn: 'root'
})
export class ClasseTurmaService {
  constructor (private httpClient: HttpCustomClient) { }

  detalheClasseTurma (idClasse: string): Observable<IClasseTurmaModel> {
    return this.httpClient.Get(HTTPURLS.DETALHECLASSETURMA, [{ name: 'id', value: idClasse }]).pipe(map((res: IResponseModel) => {
      return res.data as IClasseTurmaModel
    }))
  }

  listaClasseTurma (): Observable<IClasseTurmaModel[]> {
    return this.httpClient.Get(HTTPURLS.CONSULTACLASSETURMA).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.data as IClasseTurmaModel[]
      } else {
        [{}] as IClasseTurmaModel[]
      }
    }))
  }

  atualizaClasseTurma (classe: IClasseTurmaModel): Observable<string> {
    return this.httpClient.Put(HTTPURLS.ATUALIZACLASSE, classe).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return res.message
      } else {
        return ''
      }
    }))
  }

  novaClasseTurma (classe: IClasseTurmaModel): Observable<boolean> {
    return this.httpClient.Post(HTTPURLS.NOVACLASSE, classe).pipe(map((res: IResponseModel) => {
      if (res.success) {
        return true
      } else {
        return false
      }
    }))
  }
}
