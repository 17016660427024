<mat-sidenav-container>
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content style="overflow: hidden">
    <mat-toolbar>
      <mat-toolbar-row>
        <span>Clique na RF do professor para ver os detalhes do cadastro</span>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <mat-form-field class="search">
          <mat-label>Pesquisar</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="search"
            (keyup)="applyFilter($event)"
          />
          <button
            matSuffix
            mat-icon-button
            aria-label="search"
            (click)="search = ''"
          >
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
        <button class="button-incluir" (click)="incluirDocente()">
          Incluir
          <mat-icon color="primary">add</mat-icon>
        </button>
        <button class="button-manutencao" (click)="manutencaoDocente()">
          Manutenção
          <mat-icon color="accent">build</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </mat-sidenav-content>
  <!--
    <div class="hora-atividade">
      <h2>Cadastro de professores</h2>
      <h4>Clique na RF do professor para ver os detalhes do cadastro</h4>
      <mat-form-field class="form-field" appearance="fill">
        <mat-label>Pesquisar</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="search"
          (keyup)="applyFilter($event)"
        />
        <button
          matSuffix
          mat-icon-button
          aria-label="search"
          (click)="search = ''"
        >
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
      <div style="display: inline-flex;">
        <button class="col-button" mat-raised-button color="primary" (click)="incluirDocente()">
          Incluir
        </button>
        <button class="col-button" mat-raised-button color="primary" (click)="manutencaoDocente()">
          Manutenção
        </button>
      </div>
-->
  <mat-sidenav-content>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="rf">
          <th mat-header-cell *matHeaderCellDef>RF</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-button
              color="primary"
              (click)="detalheDocente(element)"
            >
              {{ element.rf }}
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef>Professor</th>
          <td mat-cell *matCellDef="let element">{{ element.nome }}</td>
        </ng-container>

        <ng-container matColumnDef="especialidade">
          <th mat-header-cell *matHeaderCellDef>Especialidade</th>
          <td mat-cell *matCellDef="let element">
            {{ element.especialidade }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="paginator">
      <mat-paginator
        (page)="handlePageEvent($event)"
        [length]="length"
        [pageSize]="pageSize"
        [showFirstLastButtons]="true"
        [pageSizeOptions]="itensPagina"
        [pageIndex]="pageIndex"
        aria-label="Selecione a pagina"
      >
      </mat-paginator>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
