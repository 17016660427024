/* eslint-disable no-useless-constructor */
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSidenav } from '@angular/material/sidenav'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { MatSelect } from '@angular/material/select'

import { DocenteService } from 'src/app/services/cadastros/docente.service'
import { IDocenteDetalheModel } from 'src/app/Models/docente/docente-detalhe.model'
import { IHoraAtividadeGridModel } from 'src/app/Models/docente/hora-atividade-grid.model'
import { IDocenteDomain } from 'src/app/Domains/docente/docente.domain'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { IJornadaModel } from 'src/app/Models/cadastro/jornada.model'
import { IClasseTurmaModel } from 'src/app/Models/cadastro/classe-turma.model'
import { ICargoModel } from 'src/app/Models/cadastro/cargo.model'
import { ICargoEspecialidadeModel } from 'src/app/Models/cadastro/cargo-especialidade.model'
import { map, Observable } from 'rxjs'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { IDocenteDetalheDomain } from 'src/app/Domains/docente/docente-detalhe.domain'
import { CadastroService } from 'src/app/services/cadastros/cadastro.service'
import { ICategoriaModel } from 'src/app/Models/cadastro/categoria.model'
import { IPadraoModel } from 'src/app/Models/cadastro/padrao.model'
import { PromptMessageService } from '../../../services/modal/prompt-message.service'

@Component({
  selector: 'app-docente',
  templateUrl: './docente-detalhe.component.html',
  styleUrls: ['./docente-detalhe.component.css']
})
export class DocenteDetalheComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav
  @ViewChild(MatPaginator) paginator: MatPaginator
  nomeDocente: string;
  rfDocente: string;
  idDocente: string;
  idDocenteEspecialidade: string;
  title: string;

  public jornada$ = new Observable<IJornadaModel[]>();
  public classeTurmas$ = new Observable<IClasseTurmaModel[]>();
  public cargo$ = new Observable<ICargoModel[]>();
  public especialidade$ = new Observable<ICargoEspecialidadeModel[]>();
  public categoria$ = new Observable<ICategoriaModel[]>();
  public padrao$ = new Observable<IPadraoModel[]>();
  public editForm: FormGroup;
  private docenteIdEspecialidade: any;
  public showMenu = true;
  showAgenda = true;

  displayedColumns: string[] = ['turno', 'horario', 'segunda', 'terca', 'quarta', 'quinta', 'sexta']
  dataSource: MatTableDataSource<any>;
  itensPagina: number[] = [7, 14, 22];
  initialSelection = [];
  allowMultiSelect = true;
  length: number;
  pageSize: number;
  pageIndex: number;
  showFirstLastButtons = true;
  showItens = true;

  docenteDetalhes: IDocenteDetalheModel = {} as IDocenteDetalheModel
  horaAtividadeGrid: IHoraAtividadeGridModel[] = [{} as IHoraAtividadeGridModel]

  constructor (private router: Router,
    private activatedRoute: ActivatedRoute,
    private docenteService: DocenteService,
    private cadastroService: CadastroService,
    private messageService: PromptMessageService,
    private formBuilder: FormBuilder) { }

  ngOnInit (): void {
    this.showAgenda = true
    this.docenteIdEspecialidade = this.activatedRoute.snapshot.paramMap.get('id')
    this.editForm = this.formBuilder.group({
      nome: [null, Validators.required],
      rf: [null, Validators.required],
      idPadrao: [null],
      idCategoria: [null],
      idJornada: [null, Validators.required],
      idClasse: [null, Validators.required],
      idCargo: [null, Validators.required],
      idEspecialidade: [null, Validators.required],
      sindicato: [null],
      ativo: [true]
    })

    this.categoria$ = this.cadastroService.listaCategoria()
    this.padrao$ = this.cadastroService.listaPadrao()
    this.jornada$ = this.cadastroService.listaJornada()
    this.classeTurmas$ = this.cadastroService.listaClasseTurma()
    this.cargo$ = this.cadastroService.listaCargo()
    this.especialidade$ = this.cadastroService.listaEspecialidade()
    if (this.optionsValid()) {
      this.docenteService.detalheDocente(this.docenteIdEspecialidade).subscribe(
        (res) => {
          this.docenteDetalhes = res.docenteDetalhe
          this.docenteDetalhes.cargosDescricao = res.cargosDescricao
          this.docenteDetalhes.classesDescricao = res.classesDescricao
          this.docenteDetalhes.horariosDescricao = res.horariosDescricao
          this.docenteDetalhes.jornada = res.especialidade.jornada
          this.docenteDetalhes.detalheHoraAtividade = res.detalheHoraAtividade
          this.dataSource = new MatTableDataSource(this.docenteDetalhes.detalheHoraAtividade)
          this.dataSource.paginator = this.paginator
          this.length = this.docenteDetalhes.detalheHoraAtividade.length + 1
          this.pageIndex = 0
          this.editForm.get('nome').setValue(this.docenteDetalhes.nome)
          this.editForm.get('rf').setValue(this.docenteDetalhes.rf)
          this.editForm.get('idJornada').setValue(this.docenteDetalhes.idJornada)
          this.editForm.get('idEspecialidade').setValue(this.docenteDetalhes.idEspecialidade)
          this.editForm.get('idPadrao').setValue(this.docenteDetalhes.idPadrao)
          this.editForm.get('idCategoria').setValue(this.docenteDetalhes.idCategoria)
          this.getCargos(res.cargos)
          this.getClasses(res.classes)
        }
      )
      this.title = 'Editar dados'
    } else {
      this.title = 'Incluir dados'
      this.docenteService.detalheDocente(this.docenteIdEspecialidade).subscribe(
        (res) => {
          this.docenteDetalhes.detalheHoraAtividade = res.detalheHoraAtividade
          this.dataSource = new MatTableDataSource(this.docenteDetalhes.detalheHoraAtividade)
          this.dataSource.paginator = this.paginator
          this.length = this.docenteDetalhes.detalheHoraAtividade.length
          this.pageIndex = 0
        }
      )
    }
  }

  getClasses (classes : any[]) {
    const idClasses: number[] = []
    classes.forEach((c : any) => {
      idClasses.push(c.id)
    })
    if (idClasses.length > 0) {
      this.editForm.get('idClasse').setValue(idClasses)
    }
  }

  getCargos (cargos : any[]) {
    const idCargos: number[] = []
    cargos.forEach((c : any) => {
      idCargos.push(c.id)
    })
    if (idCargos.length > 0) {
      this.editForm.get('idCargo').setValue(idCargos)
    }
  }

  updateDocente () {
    if (!this.validaCadastro()) {
      this.messageService.openDialog('Atenção', 'Verifique se todos os campos obrigatórios foram devidamente preenchidos')
      return
    }

    if (this.validHoraAtividade()) {
      const docenteDomain = {
        idDocenteEspecialidade: this.docenteIdEspecialidade,
        horaAtividade: this.dataSource.data
      }
      this.docenteService.updateHoraAtividade(docenteDomain)
        .pipe(map((resp:any) => {
          if (!resp.success) {
            this.messageService.openDialog('Atenção', resp.message)
          } else {
            const cargos: number[] = []
            this.editForm.get('idCargo').value.forEach((element: any) => {
              cargos.push(element)
            })
            const turmas: number[] = []
            this.editForm.get('idClasse').value.forEach((element: any) => {
              turmas.push(element)
            })
            const domain = {
              idDocente: this.docenteDetalhes.idDocente,
              idDocenteEspecialidade: this.editForm.get('idEspecialidade').value,
              idTurmas: turmas,
              idJornada: this.editForm.get('idJornada').value,
              idPadrao: this.editForm.get('idPadrao').value,
              idCategoria: this.editForm.get('idCategoria').value,
              idCargos: cargos,
              nome: this.editForm.get('nome').value,
              rf: this.editForm.get('rf').value,
              ativo: this.editForm.get('ativo').value
            } as IDocenteDetalheDomain
            this.docenteService.updateDetails(domain).subscribe((resp) => {
              if (!resp.success) {
                this.messageService.openDialog('Atenção', resp.message)
              } else {
                this.messageService.openDialog('Atualização', 'Realizada com sucesso.')
              }
            })
          }
        }
        )).subscribe()
    }
  }

  validHoraAtividade () :boolean {
    let qtdeOpcoesSelecionada = 0
    qtdeOpcoesSelecionada = qtdeOpcoesSelecionada + (this.dataSource.data.filter((p) => p.segunda === true)).length
    qtdeOpcoesSelecionada = qtdeOpcoesSelecionada + (this.dataSource.data.filter((p) => p.terca === true)).length
    qtdeOpcoesSelecionada = qtdeOpcoesSelecionada + (this.dataSource.data.filter((p) => p.quarta === true)).length
    qtdeOpcoesSelecionada = qtdeOpcoesSelecionada + (this.dataSource.data.filter((p) => p.quinta === true)).length
    qtdeOpcoesSelecionada = qtdeOpcoesSelecionada + (this.dataSource.data.filter((p) => p.sexta === true)).length

    if (qtdeOpcoesSelecionada > 3) {
      this.messageService.openDialog('Atenção', 'Só é permitido selecionar 3 opções de hora atividade ?')
      return false
    }
    return true
  }

  validaCadastro () :boolean {
    let valid = true
    Object.keys(this.editForm.controls).forEach(key => {
      if (this.editForm.get(key).errors) {
        this.editForm.get(key).markAsTouched()
        valid = false
      }
    })
    return valid
  }

  gerarArquivoHorarioAula () {
    this.messageService.openDialogPeriodo('Selecione um período', '')
    const docenteDomain = {
      idDocente: this.docenteDetalhes.idDocente,
      idEspecialidade: this.docenteDetalhes.id,
      nomeDocente: this.docenteDetalhes.nome
    } as IDocenteDomain
    this.docenteService.horaAtividade(docenteDomain).subscribe()
  }

  selectedMenu (url: string) {
    this.router.navigate([`/${url}`])
  }

  onBack (): void {
    this.router.navigate(['home'])
  }

  handlePageEvent (event: PageEvent) {
    this.length = event.length + 1
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
  }

  optionsValid () {
    return this.docenteIdEspecialidade && this.docenteIdEspecialidade > 0
  }

  insertDocente () {
    if (!this.validaCadastro()) {
      this.messageService.openDialog('Atenção', 'É necessário selecionar um cargo e uma jornada')
      return
    }
    if (this.validHoraAtividade()) {
      const domain = {
        idTurmas: this.editForm.get('idClasse').value,
        idJornada: this.editForm.get('idJornada').value,
        idCargos: this.editForm.get('idCargo').value,
        nome: this.editForm.get('nome').value,
        rf: this.editForm.get('rf').value,
        idCategoria: this.editForm.get('idCategoria').value,
        idPadrao: this.editForm.get('idPadrao').value,
        idEspecialidade: this.editForm.get('idEspecialidade').value,
        horaAtividade: this.dataSource.data
      } as IDocenteDetalheDomain
      this.docenteService.insert(domain).subscribe((resp) => {
        this.messageService.openDialog('Atualização', resp)
      })
    }
  }

  selecionaTurma (turmaSelecionada: MatSelect) {
    this.editForm.get('idClasse').setValue(turmaSelecionada.value)
  }
}
