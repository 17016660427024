<mat-sidenav-container>
  <mat-sidenav-content>
    <app-breadcrumb></app-breadcrumb>
  </mat-sidenav-content>
  <mat-sidenav-content>
    <div class="container content mat-elevation-z4">
      <h2>{{ title }}</h2>
      <form [formGroup]="frmDetail">
        <div class="container">
          <div class="column">
            <br />
            <mat-form-field appearance="fill">
              <mat-label>Código</mat-label>
              <input
                matInput
                formControlName="codigo"
                placeholder="codigo"
                readonly="readOnly"
              />
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field appearance="fill">
              <mat-label>Descrição</mat-label>
              <input
                matInput
                formControlName="descrição"
                placeholder="descrição"
              />
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field appearance="fill">
              <mat-label>Data cadastro</mat-label>
              <input
                matInput
                formControlName="dataInsercao"
                placeholder="data"
                readonly="readOnly"
                type="date"
              />
            </mat-form-field>
          </div>
          <div class="column">
            <mat-slide-toggle formControlName="ativo" (change)="changed()">
              {{ status }}
            </mat-slide-toggle>
          </div>
        </div>
        <div>
          <button mat-raised-button color="warn">Cancelar</button>
          <button mat-raised-button color="primary" (click)="atualiza()">
            Confirmar
          </button>
        </div>
      </form>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
