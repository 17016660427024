import { PromptMessageService } from './../../../services/modal/prompt-message.service';
import { IPerfilModel } from 'src/app/Models/configuracoes/perfil.model';
import { DatePipe } from '@angular/common'
import { Component, Injector, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { ConfiguracoesService } from 'src/app/services/configuracoes/configuracoes.service'
import * as moment from 'moment';

@Component({
  selector: 'app-perfil-usuario-detalhe',
  templateUrl: './perfil-usuario-detalhe.component.html',
  styleUrls: ['./perfil-usuario-detalhe.component.css']
})
export class PerfilUsuarioDetalheComponent implements OnInit {
  idPerfil: string
  perfilUsuarioModel: IPerfilModel
  status: string
  title: string
  protected activatedRoute: ActivatedRoute
  protected router: Router
  protected configuracoesService: ConfiguracoesService
  protected promptMessage: PromptMessageService
  public frmDetail: FormGroup
  readOnly = true

  constructor (private injector: Injector) {
    this.activatedRoute = this.injector.get(ActivatedRoute)
    this.router = this.injector.get(Router)
    this.configuracoesService = this.injector.get(ConfiguracoesService)
    this.promptMessage = this.injector.get(PromptMessageService)
    this.frmDetail = this.injector.get(FormBuilder).group({
      idPerfil: [null],
      descricao: [null, Validators.required],
      status: [null]
    })
  }

  ngOnInit () {
    this.idPerfil = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.idPerfil !== '0') {
      this.title = 'Alteração do perfil de usuário'
      this.configuracoesService.selectPerfilUsuario(parseInt(this.idPerfil)).subscribe((result) => {
        this.perfilUsuarioModel = {
          id: result.id,
          descricao: result.descricao,
          status: result.status
        }
        this.setFrmDetails()
      })
    } else {
      this.title = 'Inclusão de perfil'
      this.perfilUsuarioModel = {
        id: 0,
        descricao: '',
        status: true
      }
      this.setFrmDetails()
    }
  }

  atualiza () {
    if (this.perfilUsuarioModel.id !== 0) {
      if (!this.validChange()) {
        this.promptMessage.openDialog('Alteraçao', 'Não houve alteração de dados')
      } else {
        this.setPerfilModel()
        this.configuracoesService.updatePerfilUsuario(this.perfilUsuarioModel).subscribe((result) => {
          if (!result.success) {
            this.promptMessage.openDialog('Alteraçao', 'Não foi possível atualizar o perfil do usuário.')
          } else {
            this.promptMessage.openDialog('Alteraçao', 'Dados atualizados com sucesso.')
          }
        }
        )
      }
    } else {
      this.novo()
    }
  }

  novo () {
    if (!this.validInsert()) {
      this.promptMessage.openDialog('Alteraçao', 'Dados inválidos verifique os campos obrigatórios.')
    } else {
      this.setPerfilModel()
      this.perfilUsuarioModel.id = 0
      this.configuracoesService.insertPerfilUsuario(this.perfilUsuarioModel).subscribe((result) => {
        if (result) {
          this.promptMessage.openDialog('Inclusão', 'Perfil cadastrado com sucesso.')
        } else {
          this.promptMessage.openDialog('Inclusão', 'Não foi possível cadastrar um novo perfil.')
        }
      })
    }
  }

  setPerfilModel () {
    this.perfilUsuarioModel.id = this.frmDetail.get('idPerfil').value
    this.perfilUsuarioModel.descricao = this.frmDetail.get('descricao').value
    this.perfilUsuarioModel.status = this.frmDetail.get('status').value
  }

  setFrmDetails () {
    this.frmDetail.get('idPerfil').setValue(this.perfilUsuarioModel.id)
    this.frmDetail.get('descricao').setValue(this.perfilUsuarioModel.descricao)
    this.frmDetail.get('status').setValue(this.perfilUsuarioModel.status)
    this.status = this.perfilUsuarioModel.status ? 'Ativado' : 'Desativado'
  }

  validChange () {
    return this.frmDetail.get('descricao').value !== this.perfilUsuarioModel.descricao ||
           this.frmDetail.get('status').value !== this.perfilUsuarioModel.status
  }

  validInsert () {
    return this.frmDetail.get('descricao').value !== '' &&
           this.frmDetail.get('descricao').value !== null
  }

  onChange () {
    this.status = this.frmDetail.get('status').value ? 'Ativado' : 'Desativado'
  }
}
