/* eslint-disable no-useless-constructor */
import { Router } from '@angular/router'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, finalize, Observable, throwError } from 'rxjs'

import { environment } from './../../../environments/environment'
import { SessionStorageService } from '../session-storage.service'
import { LoadingService } from '../../services/loading/loading.service'
import { PromptMessageService } from './../../services/modal/prompt-message.service'

@Injectable()
export class HttpInterceptorCustom implements HttpInterceptor {
  private activeRequests = 0
  constructor (private sessionStorageService: SessionStorageService,
               private loadService: LoadingService,
               private promptMessageService: PromptMessageService,
               private router: Router
  ) { }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.sessionStorageService.getValue('token')
    const login = this.sessionStorageService.getValue('login')
    const _url: string = `${environment.APIURL}${environment.baseUrl}${req.url}`

    if (this.activeRequests === 0) {
      if (!(req.responseType === 'blob' && req.reportProgress)) {
        this.loadService.show()
      }
    }
    this.activeRequests++
    if (token) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
        responseType: req.responseType,
        url: _url,
        body: req.method === 'GET' ? null : Object.assign(req.body, { usuario: login })
      })

      return next.handle(authReq)
        .pipe(finalize(() => {
          this.activeRequests--
          if (this.activeRequests < 1) {
            this.loadService.hide()
          }
        }),
        catchError((respError) => {
          console.log(respError)
          if (respError instanceof HttpErrorResponse) {
            this.loadService.hide()
            if (respError.status === 401) {
              this.promptMessageService.openDialog('Acesso negado', 'Seu acesso expirou realize o login novamente.', '600px', false)
              this.sessionStorageService.clear()
              this.router.navigate(['/login'])
            } else if (respError.status === 404) {
              this.promptMessageService.openDialog('Falha na requisição', 'serviço desconhecido, entre em contato com o suporte admin@sis-afranio.net', '600px', false)
            } else {
              this.promptMessageService.openDialog('Falha na requisição', respError.error.Message ? respError.error.Message : 'Entre em contato com o suporte admin@sis-afranio.net', '600px', false)
            }
          }
          return throwError(() => {
            return new Error('Falha na requisição do serviço')
          })
        })
        )
    }

    const cloneReq = req.clone({
      responseType: req.responseType,
      url: _url
    })
    return next.handle(cloneReq)
      .pipe(finalize(() => {
        this.activeRequests--
        if (this.activeRequests < 1) {
          this.loadService.hide()
        }
      }),
      catchError((respError) => {
        if (respError instanceof HttpErrorResponse) {
          this.loadService.hide()
          this.promptMessageService.openDialog('Falha na requisição', respError.error.Message ? respError.error.Message : 'Entre em contato com o suporte admin@sis-afranio.net', '600px', false)
        }
        return throwError(() => {
          return new Error('Falha na requisição do serviço')
        })
      }))
  }
}
