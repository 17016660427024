import { ModalPeriodoComponent } from './mat-dialog/modal-periodo/modal-periodo.component';
import { ModalDocHtmlComponent } from './mat-dialog/modal-doc-html/modal-doc-html.component';
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon'
import { MatDividerModule } from '@angular/material/divider'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatListModule } from '@angular/material/list'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDialogCustomComponent } from './mat-dialog/mat-dialog-custom/mat-dialog-custom.component'
import { BooleanStringPipe } from './boolean-string.pipe'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { FieldValidValueComponent } from './field-valid-value/field-valid-value.component'
import { ModalResetMessageComponent } from './mat-dialog/modal-reset-message/modal-reset-message.component'
import { MatSelectModule } from '@angular/material/select'
import { BreadcrumbModule } from '../ui/breadcrumb/breadcrumb.module'
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    FieldValidValueComponent,
    MatDialogCustomComponent,
    BooleanStringPipe,
    ModalResetMessageComponent,
    ModalDocHtmlComponent,
    ModalPeriodoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatListModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSelectModule,
    BreadcrumbModule,
    MatDatepickerModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    FieldValidValueComponent,
    MatTableModule,
    MatPaginatorModule,
    BooleanStringPipe,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSelectModule,
    BreadcrumbModule,
    MatDatepickerModule,
    ReactiveFormsModule
  ]
})
export class SharedModule { }
